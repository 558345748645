export const types = [
    { name: 'Vida Grupo', type: 'LC', branch: 'GMM' },
    { name: 'Vida Individual', type: 'LP', branch: 'GMM' },
    { name: 'GMM Colectivo', type: 'LC', branch: 'GMM' },
    { name: 'GMM Individual', type: 'LP', branch: 'GMM' },
    { name: 'AP', type: 'LC', branch: 'GMM' }, //Accidentes Personales
    { name: 'AP Individual', type: 'LP', branch: 'GMM' },
    { name: 'Viajero', type: 'LP', branch: 'GMM' },
    { name: 'Otros Beneficios', type: 'LC', branch: 'GMM' },
    { name: 'Otros Daños', type: 'LC', branch: 'Daños' },
    { name: 'Multiple Empresarial', type: 'LC', branch: 'Daños' },
    { name: 'Transporte', type: 'LC', branch: 'Daños' },
    { name: 'RC', type: 'LC', branch: 'Daños' },
    { name: 'Obra Civil', type: 'LC', branch: 'Daños' },
    { name: 'Montaje', type: 'LC', branch: 'Daños' },
    { name: 'Hogar', type: 'LP', branch: 'Daños' },
    { name: 'Autos', type: 'LP', branch: 'Autos' },
    { name: 'Flotillas', type: 'LC', branch: 'Autos' },
];
