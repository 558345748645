import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');

// Método para obtener la poliza dado el numero de poliza
export const getPolicies = async (policy_number) => {
  console.log("Entrando a getPolicies");
  try {
    const response = await axios.get(`${apiUrl}/policy/${policy_number}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      }
    });
    console.log("Respuesta del getPolicies",response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching policy:', error);
    throw error;
  }
};
export const getPolicyDetails = async (policy_number) => {
  console.log("Entrando a getPolicyDetails");
  try {
    const response = await axios.get(`${apiUrl}/policy-details/${policy_number}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      }
    });
    console.log("Respuesta del getPolicyDetails",response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching getPolicyDetails:', error);
    throw error;
  }
};
export const findPolicyByIdPolicyData = async (id) => {
  console.log("Entrando a findPolicyByIdPolicyData");
  try {
    const response = await axios.get(`${apiUrl}/policy/${id}/details/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      }
    });
    console.log("Respuesta del findPolicyByIdPolicyData",response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching findPolicyByIdPolicyData:', error);
    throw error;
  }
};
export const getPoliciesByHolder = async (policy_holder_id) => {
  try {
    const response = await axios.get(`${apiUrl}/policies/findByHolder/${policy_holder_id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Respuesta de getPoliciesByHolder",response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching policy:', error);
    throw error;
  }
};

// Método para agregar una nueva poliza
export const postPolicy = async (data) => {
  console.log("Entrando a postPolicy: ", data);

  try {
    const response = await axios.post(`${apiUrl}/policies`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,

      },
    });
    console.log("Repuesta del post policy", response)
    return response.data;
  } catch (error) {
    console.log(`La informacion a mandar ERROR: ${data}`)

    console.error('Error adding policy:', error);
    throw error;
  }
};

// Método para actualizar una aseguradora existente
export const updatePolicy = async (policyId, policyData) => {
  try {
    console.log("Datos a actualizar de la pliza", policyData)
    const response = await axios.patch(`${apiUrl}/policies/${policyId}`, policyData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    });
    console.log("Respuesta de updatePolicy:", response);
    return response.data;
    
  } catch (error) {
    console.error('Error updating insurance:', error);
    throw error;
  }
};
export const deletePolicy = async (policyId) => {
  try {
    const token = localStorage.getItem('token');

    const response = await axios.delete(`${apiUrl}/policies/${policyId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    console.log("Respuesta de deletePolicy",response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting policy:', error);
    throw error;
  }
};
//Agregar un pdf de caratula a la poliza
export const uploadDeclaration = async (id, file) => {
  console.log("datos", file);
  const formData = new FormData();
  formData.append('pdf', file); 

  try {
    const response = await axios.post(`${apiUrl}/policies/upload/declaration/${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del uploadDeclaration", response);
    return response.data;
  } catch (error) {
    console.error('Error al subir la declaración:', error.response ? error.response.data : error.message);
    throw error;
  }
};
