import React, { useState } from 'react';
import DashboardCertificates from '../../components/Certificates/DashboardCertificates';
import DashboardProcedures from '../../components/Procedures/DashboardProcedures';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('Vida');

  const renderContent = () => {
    switch (activeTab) {
      case 'Vida':
        return <DashboardProcedures type={'Vida'} />;
      case 'Vida Grupo':
        return <DashboardProcedures type={'Vida Grupo'} />;
      case 'GMM Individual':
        return <DashboardProcedures type={'GMM Individual'} />;
      case 'GMM Colectivo':
        return <DashboardProcedures type={'GMM Colectivo'} />;
      case 'Otros':
        return <DashboardProcedures type={'Otros'} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Menú de pestañas */}
      <div style={styles.tabContainer}>
        <div 
          style={activeTab === 'Vida' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('Vida')}
        >
          Vida
        </div>
        <div 
          style={activeTab === 'Vida Grupo' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('Vida Grupo')}
        >
          Vida Grupo
        </div>
        <div 
          style={activeTab === 'GMM Individual' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('GMM Individual')}
        >
          GMM Individual
        </div>
        <div 
          style={activeTab === 'GMM Colectivo' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('GMM Colectivo')}
        >
          GMM Colectivo
        </div>
        <div 
          style={activeTab === 'Otros' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('Otros')}
        >
          Otros
        </div>
      </div>

      {/* Contenido de la pestaña activa */}
      <div style={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

const styles = {
  tabContainer: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    marginBottom: '16px',
  },
  tab: {
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
  },
  activeTab: {
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: '2px solid #007bff',
    fontWeight: 'bold',
  },
  content: {
    padding: '16px',
  }
};

export default Dashboard;
