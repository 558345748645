import React, { useState } from 'react';
import ExcelUpload from '../Excel/ExcelUpload';
import { Table, Button } from 'react-bootstrap';
import { attachInsuredProcedure } from '../../api/ProceduresService';

const InsuredSection = ({procedureId}) => {
  const [uploadedData, setUploadedData] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false); // Nuevo estado para controlar si hay archivo

  // Función que se ejecutará cuando los datos del Excel sean parseados
  const handleDataParsed = (data) => {
    setUploadedData(data);
    setFileUploaded(true); // Indica que se ha subido un archivo
  };

  // Función para quitar el archivo y los datos
  const handleRemoveFile = () => {
    setUploadedData([]);
    setFileUploaded(false); // Restablece el estado cuando se quita el archivo
  };
  const handleUploadInsureds = async () =>{
    console.log(procedureId, uploadedData)
    try {
      await attachInsuredProcedure(procedureId, uploadedData)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div>
      {/* Mostrar componente ExcelUpload solo si no hay archivo subido */}
      {!fileUploaded && <ExcelUpload onDataParsed={handleDataParsed} />}

      {/* Renderizar tabla de datos cuando haya datos subidos */}
      {fileUploaded && uploadedData.length > 0 && (
        <div className="mt-4">
          <h3>Datos subidos:</h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                {Object.keys(uploadedData[0]).map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {uploadedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Botón para quitar el archivo y los datos */}
          <Button variant="danger" onClick={handleRemoveFile}>
            Quitar archivo
          </Button>
          <Button variant="success" onClick={handleUploadInsureds}>
            Cargar Asegurados
          </Button>
        </div>
      )}
    </div>
  );
};

export default InsuredSection;
