import React, { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ProceduresForm from '../../components/Procedures/ProceduresForm';
import MainTableProcedures from '../../components/Tables/TableProcedures/TableListProcedures';

function Procedures() {
    const [buttonAdd, setButtonAdd] = useState(false);

    const handleChange = () => {
        setButtonAdd(true);
    };

    const handleCancel = () => {
        setButtonAdd(false);
    };

    return (
        <div>
            <h2>Tramites</h2>
            {!buttonAdd && (

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleChange}
                        startIcon={<AddIcon />}
                        style={{ marginBottom: '1rem' }}
                    >
                        Crear Nuevo Trámite
                    </Button>
                </div>
            )}

            {buttonAdd && (
                <div>
                    <div style={{ marginBottom: '1rem' }}>
                        <ProceduresForm onClose={handleCancel} />
                    </div>
                </div>
            )}
            <MainTableProcedures />
        </div> 
    );
}

export default Procedures;
