import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getHolderByOrganization } from '../../../api/PolicyHolderService';

const SearchPolicyHolder = ({ onSelect, organization, organizationForeign }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(null); // Estado para el valor seleccionado
    const [inputValue, setInputValue] = useState(''); // Estado para el valor del campo de entrada
    const [data, setData] = useState([]);

    useEffect(() => {
        if (organizationForeign && organizationForeign.length > 0) {
            setData(organizationForeign);
        } else if (organization) {
            fetchPolicyHolderByOrganization(organization);
        }
    }, [organization, organizationForeign]);

    const fetchPolicyHolderByOrganization = async (id) => {
        try {
            const response = await getHolderByOrganization(id);
            setData(response.holders || []); // Asegúrate de manejar el caso en que no hay holders
        } catch (error) {
            console.error(error);
        }
    };

    // Filtrar opciones basadas en la búsqueda
    const filteredOptions = data.map(datas => ({
        id: datas.id_policy_holder,
        label: `${datas.id_policy_holder}-${datas.business_name}`
    }));

    return (
        <Autocomplete
            disablePortal
            id="autocomplete"
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onSelect(newValue?.id);  // Llamada a la función de callback
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Buscar Contratante" />}
        />
    );
};

export default SearchPolicyHolder;
