import axios from 'axios';
import { apiUrl } from '../global';

const token = localStorage.getItem('token');

export const postEndorsement = async (id) => {
    try {
        const response = await axios.post(
            `${apiUrl}/endorsement/${id}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error adding endorsement:', error);
        throw error;
    }
};


export const updateEndorsement = async (id, data) => {
    console.log("Entrando a updateEndorsement")
    console.log("DAtos enviados", data)
    try {
        const response = await axios.put(`${apiUrl}/endorsement/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log("Respuesta:", response);
        return response.data;

    } catch (error) {
        console.error('Error updating updateEndorsement:', error);
        throw error;
    }
};
export const attachPDFEndorsement = async (id, data) => {
    try {
        const response = await axios.post(`${apiUrl}/attachPDF/endorsement/${id}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log('respuesta del attachPDFEndorsement', response.data);
        return response.data;
    } catch (error) {
        console.error('Error attachPDFEndorsement:', error);
        throw error;
    }
};

export const deleteEndorsement = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/endorsement/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleteEndorsement:', error);
        throw error;
    }
};

export const getInsuredProcedureAsociates = async (id) => {
    console.log("Entrando a getInsuredProcedureAsociates");
    try {
        const response = await axios.get(`${apiUrl}/getInsuredProcedureAsociates/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log("Repuesta del getInsuredProcedureAsociates:", response.data)
        return response.data;
    } catch (error) {
        console.error('Error fetching Images of getInsuredProcedureAsociates:', error);
        throw error;
    }
}
// Método para actualizar existente
export const updateInsuredProcedures = async (id, data) => {
    console.log("Entrando a updateInsuredProcedures")
    console.log("DAtos enviados", data)
    try {
        const response = await axios.put(`${apiUrl}/insuredProcedureAsociates/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log("Respuesta:", response);
        return response.data;

    } catch (error) {
        console.error('Error updating subgroup:', error);
        throw error;
    }
};