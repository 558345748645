import React, { useState, useEffect } from 'react';
import { getOrganizations, updateOrganization, deleteOrganization } from '../../api/OrganizationService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { getRole } from '../../api/userService';
import { infoGlobal } from '../../global';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationForm from './OrganizationForm';

const MySwal = withReactContent(Swal);

function OrganizationList() {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organizations.data);
  const user = useSelector((state) => state.users.data);
  const [orgDatas, setOrgDatas] = useState([]);

  const [editingId, setEditingId] = useState(null);
  const [originalData, setOriginalData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [role] = useState('');
  const canDeleteOrCreate = user === 'admin' || user === 'executive';
  
  useEffect(() => {
      if (!organization.length) {
          infoGlobal.fetchOrganizations();
      } else {
          setOrgDatas(organization)
      }
      if (!user.length) {
          infoGlobal.fetchRole();
      }
  }, [organization, user, dispatch]);

  const handleEdit = (id) => {
    setEditingId(id);
    const organizationToEdit = orgDatas.find(organization => organization.id_organization === id);
    setOriginalData(organizationToEdit); // Al iniciar la edición, guarda los datos originales 
    setUpdatedData(organizationToEdit);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setUpdatedData({});
  };

  const handleSaveEdit = async (id) => {
    setEditingId(null);
    try {
      // Filtrar solo los campos modificados comparando los datos actualizados con los datos originales
      const changedFields = Object.keys(updatedData).filter(key => updatedData[key] !== originalData[key]);
      // Si no hay campos modificados, no hay necesidad de enviar una solicitud de actualización
      if (changedFields.length === 0) {
        console.log('No se han realizado cambios.');
        return;
      }
      const updatedFields = {};
      // Crear un objeto solo con los campos modificados
      changedFields.forEach(key => {
        updatedFields[key] = updatedData[key];
      });
      console.log("Datos nuevos:", updatedFields);
      // Llamar a la función de actualización de la API con los datos modificados
      await updateOrganization(id, updatedFields);
      // Restablecer los datos originales y los datos actualizados después de guardar los cambios
      setOriginalData({});
      setUpdatedData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(() => {
        infoGlobal.fetchOrganizations();
      });
    } catch (error) {
      console.error('Error updating organizations:', error);
      // Manejar cualquier error que ocurra durante la actualización
      // Restablecer los datos originales y los datos actualizados en caso de error
      setOriginalData({});
      setUpdatedData({});
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };
  const handleUpdateList = async () => {
    console.log("HandleUpdate")
    infoGlobal.fetchOrganizations();
  };
  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar esta Organizacion?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteOrganization(id);
          // Filtrar la lista de ejecutivos para eliminar solo el ejecutivo con el ID correspondiente
          setOrgDatas(prevExecutives => prevExecutives.filter(organization => organization.id_organization !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'La organizacion se ha eliminado correctamente.',
          });
          infoGlobal.fetchOrganizations();
        } catch (error) {
          console.error('Error deleting Org:', error);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error al eliminar a la Organizacion.',
          });
        }
      }
    });
  };

  const fetchOrganizations = async () => {
    try {
      const data = await getOrganizations();
      setOrgDatas(data.organizations);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setUpdatedData({ ...updatedData, [fieldName]: value });
  };

  return (
    <div>
      {canDeleteOrCreate && <OrganizationForm updateList={handleUpdateList} />}
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Nombre de la Organizacion</th>
            <th scope="col">Numero de Telefono</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {orgDatas.map((organization) => (
            <tr key={organization.id_organization}>
              <td>{editingId === organization.id_organization ? <input type="text" defaultValue={organization.name} onChange={(e) => handleFieldChange('name', e.target.value)} /> : organization.name}</td>

              <td>{editingId === organization.id_organization ? <input type="number" defaultValue={organization.phone_number} onChange={(e) => handleFieldChange('phone_number', e.target.value)} /> : organization.phone_number}</td>

              {canDeleteOrCreate && (
                <td>
                {editingId === organization.id_organization ? (
                  <>
                    <button className="btn btn-success btn-sm me-1" onClick={() => handleSaveEdit(organization.id_organization)}>Guardar</button>
                    <button className="btn btn-secondary btn-sm" onClick={handleCancelEdit}>Cancelar</button>
                  </>
                ) : (
                  <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(organization.id_organization)}>Editar</button>
                )}
                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(organization.id_organization)}>Borrar</button>
              </td>
)} 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrganizationList
