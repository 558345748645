import React, { useState } from 'react';
import { changeRole } from '../api/userService';

function ChangeRole() {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const roles = ['admin', 'executive', 'operator', 'insured'];


    const handleChangeRole = async (e) => {
        e.preventDefault();
        try {
            const response = await changeRole(email, role);
            setResponseMessage(response.message); // Mostrar el mensaje de la respuesta
        } catch (error) {
            console.error('Error cambiando role:', error);
            setResponseMessage(`Error cambiando role:  ${error.response.data.message}`); // Mostrar mensaje de error genérico en caso de falla
        }
    };

    return (
        <div className="w-100 px-3 mx-auto mt-4">
            <div className="card">
                <div className="card-header bg-primary text-white">Cambiar Rol de Usuario</div>
                <div className="card-body">
                    <form onSubmit={handleChangeRole}>
                        <div className="form-group row">
                            <label htmlFor="email" className="col-sm-3 col-form-label">Email:</label>
                            <div className="col-sm-9">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="role" className="col-sm-3 col-form-label">Nuevo Rol:</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control"
                                    id="role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    required
                                >
                                    {roles.map((r) => (
                                        <option key={r} value={r}>{r}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn btn-primary">Cambiar Rol</button>
                            </div>
                        </div>
                        {responseMessage && (
                            <div className="mt-3 alert alert-info" role="alert">
                                {responseMessage}
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ChangeRole;
