import { configureStore } from '@reduxjs/toolkit';
import organizationReducer from '../features/organization/organizationSlice';
import usersReducer from '../features/user/userSlice';
import { insurerReducer } from '../features/insurer/insurerSlice';
import { executiveReducer } from '../features/executive/executiveSlice';
import { poolsReducer } from '../features/pools/poolsSlice';
import { modalPolicyReducer } from '../features/modalSlice/modalPolicySlice';

export const store = configureStore({
  reducer: {
    organizations: organizationReducer,
    users: usersReducer,
    insurer:insurerReducer,
    executive:executiveReducer,
    pool:poolsReducer,
    modalPolicy: modalPolicyReducer,
  },
});

export default store;
