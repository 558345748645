import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../global';
import { postCarousel, getCarousel, deleteCarousel } from "../api/CarouselService";
import fetchCarousels from "./CarruselList";


const CarouselForm = ({ updateCarouselList }) => {
    const [formData, setFormData] = useState({ title: '', description: '', image: null, active: 0 });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (event) => {
        setFormData({ ...formData, image: event.target.files[0] });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('image', formData.image);
            formDataToSend.append('active', formData.active);
            formDataToSend.append('link', formData.link);
            // Envía el formulario al servidor
            await postCarousel(formDataToSend)
            // Reinicia el formulario después de enviar los datos
            setFormData({ title: '', description: '', image: null });
            updateCarouselList();
            // Aquí podrías mostrar un mensaje de éxito o redirigir al usuario a otra página
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            // Aquí podrías mostrar un mensaje de error al usuario
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">Título</label>
                <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="description" className="form-label">Descripción</label>
                <input type="text" id="description" name="description" value={formData.description} onChange={handleChange} className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="link" className="form-label">Activo</label>
                <select
                    name="active"
                    value={formData.active}
                    onChange={handleChange}
                    className="form-control"
                >
                    <option value='1'>Si</option>
                    <option value='0'>No</option>
                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="link" className="form-label">Enlace</label>
                <input type="link" id="link" name="link" value={formData.link} onChange={handleChange} className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="image" className="form-label">Imagen</label>
                <input type="file" id="image" name="image" accept="image/*" onChange={handleImageChange} className="form-control" />
            </div>

            <button type="submit" className="btn btn-primary">Enviar</button>
        </form>
    );
};

export default CarouselForm;
