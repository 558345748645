import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Routes, Route } from 'react-router-dom';
import LoginForm from './components/Login/LoginForm';
import DashboardRoutes from "./dashboardRoutes";
import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Leer el token del localStorage al montar el componente
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem('token', token); // Almacenar el token en localStorage al iniciar sesión
  };

  return (
    <div>
      {token ? (
        <div>
          <DashboardRoutes />
        </div>
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
  );
}


export default App;
