import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');
// Método para obtener una Organizacion dando el id
export const getInsuredsByCertificate = async (id) => {
  console.log("Entrando a getInsuredsByCertificate");
  try {
    const response = await axios.get(`${apiUrl}/certificate/${id}/insured`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching insureds:', error);
    throw error;
  }
};

//Crear un asegurado y asociarlo con un certificado
export const attachCertificate = async (id_certificate, data) => {
  try {
    console.log("postCertificate, Datos: ", data);

    const response = await axios.post(`${apiUrl}/certificates/${id_certificate}/attach`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log(`La informacion a mandar: ${JSON.stringify(data)}`);
    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error adding certificate:', error);
    throw error;
  }
};
//Asociar un asegurado con la poliza Unicamente para individual
export const attachPolicy = async (id_policy, data) => {
  try {
    console.log("attachPolicy, Datos: ", data);

    const response = await axios.post(`${apiUrl}/policy/${id_policy}/attach`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log(`La informacion a mandar: ${JSON.stringify(data)}`);
    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error attachPolicy:', error);
    throw error;
  }
};

//Anadir Email al certificado
export const addEmailToCertificate = async (id_certificate, email) => {
  try {
    console.log("addEmailToCertificate, Datos: ", email);

    const response = await axios.put(`${apiUrl}/certificates/${id_certificate}/add-email`, email, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error adding email to certificate:', error);
    throw error;
  }
};



export const getInsuredsByPolicyId = async (id) => {
  console.log("Entrando a getInsuredsByPolicyId");
  try {
    const response = await axios.get(`${apiUrl}/getInsuredsByPolicyId/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("DAtos getInsuredsByPolicyId", response.data);

    return response.data;
  } catch (error) {
    console.error('Error fetching insureds:', error);
    throw error;
  }
};
export const deleteInsured = async (id_insured) => {
  try {
    console.log(`Intentando eliminar asegurado con ID: ${id_insured}`);

    const response = await axios.delete(`${apiUrl}/insured/${id_insured}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error al eliminar el asegurado:', error);
    throw error;
  }
};

export const deleteAllInsureds = async (id_policy) => {
  try {
    console.log(`Intentando eliminar los asegurados de la poliza:  ${id_policy}`);

    const response = await axios.delete(`${apiUrl}/insured/deleteAllInsureds/${id_policy}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error al eliminar los asgurados:', error);
    throw error;
  }
};
export const sendMassiveCertificates = async (data) => {
  try {
    console.log("SendMassiveCertificates, Datos: ", data);

    const response = await axios.post(`${apiUrl}/email/send-massive-certificates`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log(`La informacion a mandar: ${JSON.stringify(data)}`);
    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error SendMassiveCertificates:', error);
    throw error;
  }
};
export const addEmailToInsured = async (id, data) => {
  try {
    console.log("addEmailToCertificate, Datos: ", data);

    const response = await axios.put(`${apiUrl}/insured/${id}/add-email`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error adding email to certificate:', error);
    throw error;
  }
};
export const updateInsured = async (id, data) => {
  try {
    console.log("addEmailToCertificate, Datos: ", data);

    const response = await axios.put(`${apiUrl}/insured/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Response from server:", response.data);

    return response.data;
  } catch (error) {
    console.error('Error adding email to certificate:', error);
    throw error;
  }
};