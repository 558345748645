import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Row, Col, Table, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button as ButtonMui } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';

import { getLogs } from '../../api/LogsServices';

import { attachPDFEndorsement, deleteEndorsement, postEndorsement, updateEndorsement } from '../../api/EndorsementService';

import { findPolicyByOrganization } from '../../api/OrganizationService';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Card } from 'react-bootstrap';
import SearchPolicy from '../SearchAutoComplete/Policy/SearchPolicy';
import TableInsuredProcedure from './TableInsuredProcedure';


import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import InsuredSection from './InsuredSection';
import TestComponent from '../../features/testpage';


const MySwal = withReactContent(Swal);

function ProcedureDetails({ data, id }) {
    console.warn(data)

    const dispatch = useDispatch();
    const organization = useSelector((state) => state.organizations.data);
    const insurers = useSelector((state) => state.insurer.data);
    const executives = useSelector((state) => state.executive.data);
    const [editForm, setEditForm] = React.useState(false);
    const [pdfEndorsement, setPdfEndorsement] = React.useState(new FormData());
    const [changes, setChanges] = React.useState('');
    const [rows, setRows] = React.useState(data?.endorsements || []);
    console.log("Endosos", data?.endorsements)
    const [formEndorsementData, setFormEndorsementData] = React.useState({
        policy_number: '',
        endorsement_number: '',
        insure_start_date: '',
    });
    const [formData, setFormData] = React.useState({
        insurer_folio: data?.insurer_folio || '',
        internal_folio: data?.internal_folio || '',
        procedure_type: data?.procedure_type || '',
        policy_branch: data?.policy_branch || '',
        details: data?.details || 'NA',
        request_method: data?.request_method || '',
        endorsement_number: data?.endorsement_number || '',
        policy_number: data?.policy?.policy_number || '',
        policy_type: data?.policy?.policy_type || '',
        insurer_name: data?.insurer?.commercial_name || '',
        phone_claims: data?.insurer?.phone_claims || '',
        executive_name: data?.executive?.name || '',
        expected_date: data?.expected_date || '',
        client_request_date: data?.client_request_date || '',
        insurer_process_start_date: data?.insurer_process_start_date || '',
        response_date: data?.response_date || '',
        procedure_completion_date: data?.procedure_completion_date || '',
        client_delivery_date: data?.client_delivery_date || '',
        movement_type: data?.movement_type || '',
        status: data?.status || '',
        organization: data?.organization || '',
        contact: data?.contact || '',
        policy_id: '',
        endorsement_number: '',
        insure_start_date: '',
        rows: ''
    });
    const [editIndex, setEditIndex] = useState(null);
    const [policies, setPolicies] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState(null);

    const [showCreateModal, setShowCreateModal] = useState(null);
    const [showAttachInsuredEndorsement, setShowAttachInsuredEndorsement] = useState(false);

    const handleOpenCreateModal = () => {
        setShowCreateModal(true)
    };
    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleOpenAttachInsured = () => setShowAttachInsuredEndorsement(true);
    const handleCloseAttchInsured = () => setShowAttachInsuredEndorsement(false);
    const policiesCount = new Set(
        data?.insured_procedures
            .map(item => item.policy_number)
            .filter(policyNumber => policyNumber !== null)
    ).size;
    const endorsementCount = rows.length;


    useEffect(() => {
        fetchLogs()
        fetchPolicies()
    }, []);
    const fetchPolicies = async () => {
        const response = await findPolicyByOrganization(data?.organization.id_organization)
        console.log("response", response)
        setPolicies(response)
    }
    const handlePolicyChange = (event) => {
        console.log("poliza id", event);
        setSelectedPolicy(event)
        setFormEndorsementData(prevFormData => ({
            ...prevFormData,
            policy_id: event
        }));
    };
    const fetchLogs = async () => {
        const response = await getLogs(data?.id_procedure, 'procedures')
        setChanges(response)
        console.log(response)
    };

    if (!data) return null;

    const handleClose = () => {
        //onHide();
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleRowChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = rows.map((row, i) =>
            i === index ? { ...row, [name]: value } : row
        );
        setRows(updatedRows);
    };


    const addRow = async () => {
        try {
            const response = await postEndorsement(data.id_procedure);
            console.log(response);

            if (response.endorsement) {
                setRows(prevRows => [
                    ...prevRows,
                    response.endorsement,
                ]);
            } else {
                console.error('No se encontró el endorsement en la respuesta', response);
            }
        } catch (error) {
            console.error('Error al agregar el endorsement:', error);
        }
    };

    const removeRow = async (id, index) => {
        try {
            console.log('rows[index].id: ', rows)
            const endorsementId = rows[index].id;
            const response = await deleteEndorsement(id);
            // Actualiza el estado local eliminando la fila correspondiente.
            setRows(prevRows => prevRows.filter((_, i) => i !== index));

        } catch (error) {
            console.error('Error al eliminar el endorsement:', error);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormData({ ...formData, ['rows']: rows });
        try {
            //await updateStatusCertificate(data.id_certificate, formData);
            //await updateProcedure(data.id_procedure, formData)
            console.log("FormData", formData)
            console.log("rows", rows)
            setEditForm(false)
            MySwal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El certificado se ha actualizado correctamente.',
            });
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                footer: `<pre>${error.response.data.message || 'Error desconocido'}</pre>`,

                html: `Ha ocurrido un error al Actualizar el certificado.<br/><strong>${error.response.data.exception}</strong>`,
            });
        }
    };
    const handleEdit = () => {
        if (editForm) {
            setEditForm(false)
        } else {
            setEditForm(true)
        }
    }
    const handleRowEndorsementChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };

    const handleFileEndorsementChange = (event) => {
        const files = Array.from(event.target.files); // Convertimos la lista de archivos en un array
        console.log('Archivos seleccionados:', files);

        // Crear un nuevo FormData y agregar los archivos
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`pdfs[${index}]`, file); // Agrega cada archivo con un nombre único
        });

        setPdfEndorsement(formData);
    };


    const enableEditing = (index) => {
        setEditIndex(index);
    };
    const saveEndorsement = async () => {
        const row = rows[editIndex];
        const formDataEndorsement = new FormData();
        console.log('aaaaaaaa', row)
        // Aseguramos que los datos requeridos estén presentes
        formDataEndorsement.append('policy_number', row.policy_number);
        formDataEndorsement.append('endorsement_number', row.endorsement_number);
        formDataEndorsement.append('insure_start_date', row.insure_start_date);
        console.log('selectedPolicy', selectedPolicy)
        formDataEndorsement.append('policy_id', selectedPolicy);
        try {
            // Llamada a la función para actualizar el respaldo
            await updateEndorsement(row.id_endorsement, formDataEndorsement);
            if (!pdfEndorsement) {
                await attachPDFEndorsement(row.id_endorsement, pdfEndorsement);
            }

            setEditIndex(null); // Deshabilita la edición después de guardar
        } catch (error) {
            console.error("Error al guardar los datos:", error);
        }
    };


    const cancelEdit = () => {
        setEditIndex(null); // Cancela la edición y resetea el índice
    };


    const getDaysElapsedColor = (daysElapsed, thresholds) => {
        const { greenThreshold, yellowThreshold } = thresholds;

        if (daysElapsed <= greenThreshold) {
            return '#28a745'; // Verde
        } else if (daysElapsed <= yellowThreshold) {
            return '#ffc107'; // Amarillo
        } else {
            return '#dc3545'; // Rojo
        }
    };

    const calculateDaysElapsed = (date, thresholds = { greenThreshold: 2, yellowThreshold: 4 }) => {
        if (!date) return { days: 'NA', color: '#6c757d' }; // Retorna 'NA' si no hay fecha y color gris
        const requestDate = new Date(date);
        const today = new Date();
        const timeDiff = today - requestDate;
        const daysElapsed = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const color = getDaysElapsedColor(daysElapsed, thresholds);
        return { days: `${daysElapsed} Días`, color };
    };
    return (
        <div>
            <Button
                variant="primary"
                style={{
                    position: 'fixed',
                    top: '40px',
                    right: '20px',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    fontSize: '24px',
                    zIndex: 2000, // Asegura que esté por encima de otros elementos

                }}
                onClick={handleOpenCreateModal}
            >
                +
            </Button>
            <Row>
                <Col md={9}>
                    <Card className="shadow p-3 bg-white rounded" style={{ padding: '16px', borderRadius: '8px' }}>
                        <Card.Body>
                            <h4>Generales</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                                    <input
                                        type="date"
                                        name="client_request_date"
                                        value={formData.client_request_date || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingClientRequestDate"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingClientRequestDate">Solicitud del Cliente</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="organization"
                                        value={formData.organization.name || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingOrganization"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingOrganization">Organización</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="insurer_name"
                                        value={formData.insurer_name || ''}
                                        disabled={true}
                                        className="form-control"
                                        id="floatingInsurerName"
                                        placeholder=" "
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingInsurerName">Aseguradora</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                                    <select
                                        type="text"
                                        name="policy_branch"
                                        value={formData.policy_branch || ''}
                                        disabled={!editForm}
                                        onChange={handleChange}
                                        className="form-select"
                                        id="floatingPolicyBranch"
                                        placeholder=" "
                                        style={{ fontSize: 'inherit' }}
                                    >
                                        <option value="Vida">Vida</option>
                                        <option value="Vida Grupo">Vida Grupo</option>
                                        <option value="Gmm Individual">GMM Individual</option>
                                        <option value="GMM Colectivo">GMM Colectivo</option>
                                        <option value="Otro">Otro</option>
                                    </select>
                                    <label htmlFor="floatingPolicyBranch">Rama</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="contact"
                                        value={formData.contact.name || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingContact"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingContact">Quien Solicita</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 150px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="request_method"
                                        value={formData.request_method || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingRequestMethod"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingRequestMethod">Medio de Solicitud</label>
                                </div>

                                <div style={{ width: '100%', borderBottom: '1px solid #ddd' }}>
                                    <h3 style={{ margin: '0' }}>Trámite</h3>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                    <input
                                        type="date"
                                        name="insurer_process_start_date"
                                        value={formData.insurer_process_start_date || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingInsurerProcessStartDate"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingInsurerProcessStartDate">Inicio de proceso de la aseguradora</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="insurer_folio"
                                        value={formData.insurer_folio || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingInsurerFolio"
                                        placeholder=" "
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="floatingInsurerFolio">Folio Aseguradora</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                    <select
                                        type="text"
                                        name="request_method"
                                        value={formData.request_method || ''}
                                        disabled={!editForm}
                                        className="form-select"
                                        id="floatingRequestMethod"
                                        placeholder=" "
                                        onChange={handleChange}
                                    >
                                        <option value="Email">Email</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Teléfono">Teléfono</option>
                                        <option value="Reunión">Reunión</option>
                                        <option value="otro">Otro</option>
                                    </select>
                                    <label htmlFor="floatingRequestMethod">Método de Solicitud</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                    <input
                                        type="date"
                                        name="insurer_process_start_date"
                                        value={formData.insurer_process_start_date || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingInsurerProcessStartDate"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingInsurerProcessStartDate">Días transcurridos (Aseguradora)</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="procedure_type"
                                        value={formData.procedure_type || ''}
                                        disabled={!editForm}
                                        className="form-control"
                                        id="floatingProcedureType"
                                        placeholder=" "
                                        onChange={handleChange}
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingInsurerFolio">Tipo de Tramite</label>
                                </div>
                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                    <input
                                        type="text"
                                        name="internal_folio"
                                        value={formData.internal_folio || ''}
                                        disabled={true}
                                        className="form-control"
                                        id="floatingInternalFolio"
                                        placeholder=" "
                                        style={{ fontSize: 'inherit' }}
                                    />
                                    <label htmlFor="floatingInternalFolio">Folio Interno</label>
                                </div>
                            </div>
                            <div style={{ width: '100%', borderBottom: '1px solid #ddd', marginBottom: '20px' }}>
                                <div className="d-flex align-items-center text-center ">
                                    <h3 style={{ margin: '0', textAlign: 'center' }}>
                                        Endosos {endorsementCount}/{policiesCount}
                                    </h3>
                                    <ButtonMui
                                        variant="contained"
                                        color="success"
                                        onClick={addRow}
                                        startIcon={<AddIcon />}
                                        style={{ marginLeft: '1rem' }}
                                    >
                                        Añadir Endoso
                                    </ButtonMui>
                                </div>
                            </div>

                            <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden', minHeight: '200px' }}>

                                <Row className="mb-3">
                                    {rows.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', position: 'relative' }}>


                                                <div className="col-md-2">
                                                    <SearchPolicy
                                                        data={policies}
                                                        onSelect={handlePolicyChange}
                                                        isDisabled={editIndex !== index}

                                                    />
                                                </div>
                                                <div className="mb-3 form-floating" style={{ flex: '20px', fontSize: '12px' }}>
                                                    <input
                                                        type="text"
                                                        name="endorsement_number"
                                                        value={row.endorsement_number}
                                                        onChange={(e) => handleRowEndorsementChange(index, e)}
                                                        className="form-control"
                                                        placeholder=" "
                                                        style={{ fontSize: 'inherit' }}
                                                        disabled={editIndex !== index}
                                                    />
                                                    <label>No de Endoso</label>
                                                </div>
                                                <div className="mb-3 form-floating" style={{ flex: '20px', fontSize: '12px' }}>
                                                    <input
                                                        type="date"
                                                        name="insure_start_date"
                                                        value={row.insure_start_date}
                                                        onChange={(e) => handleRowEndorsementChange(index, e)}
                                                        className="form-control"
                                                        placeholder=" "
                                                        style={{ fontSize: 'inherit' }}
                                                        disabled={editIndex !== index}
                                                    />
                                                    <label>Inicio de proceso de la aseguradora</label>
                                                </div>
                                                <div className="mb-3 form-floating" style={{ flex: '1 1 100px', fontSize: '12px' }}>
                                                    <input
                                                        type="file"
                                                        name="pdf"
                                                        accept="application/pdf"
                                                        onChange={handleFileEndorsementChange}
                                                        className="form-control"
                                                        placeholder=" "
                                                        style={{ fontSize: 'inherit' }}
                                                        disabled={editIndex !== index}
                                                    />
                                                    <label>PDFs</label>
                                                </div>


                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                    {editIndex !== index ? (
                                                        <Button
                                                            type="button"
                                                            onClick={() => enableEditing(index)} // Activa edición solo para esta fila
                                                            className="btn btn-primary btn-md"
                                                        >
                                                            <EditRoundedIcon />
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                type="button"
                                                                onClick={saveEndorsement} // Guarda cambios
                                                                className="btn btn-success btn-md"
                                                            >
                                                                <SaveIcon />
                                                            </Button>
                                                            <Button
                                                                type="button"
                                                                onClick={cancelEdit} // Cancela la edición
                                                                className="btn btn-secondary btn-md"
                                                            >
                                                                <CancelIcon />
                                                            </Button>
                                                        </>
                                                    )}
                                                    {!editIndex && (
                                                        <>
                                                            <Button
                                                                type="button"
                                                                onClick={() => removeRow(row.id_endorsement, index)}
                                                                className="btn btn-danger btn-md"
                                                            >
                                                                <DeleteForeverRoundedIcon />
                                                            </Button>
                                                            <Button
                                                                type="button"
                                                                onClick={() => handleOpenAttachInsured(row.id_endorsement, index)}
                                                                className="btn btn-success btn-md"
                                                            >
                                                                <PersonAddAlt1Icon />
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>

                                                {showAttachInsuredEndorsement && (
                                                    <TestComponent show={showAttachInsuredEndorsement} handleClose={handleCloseAttchInsured} endorsementsData={rows} organization={data?.organization.id_organization}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </Row>
                                <Modal
                                    show={showCreateModal}
                                    onHide={handleCloseCreateModal}
                                    dialogClassName="modal-nested" // Clase personalizada para tamaño y estilo
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Cargar Asegurados</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <InsuredSection procedureId={data.id_procedure} />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseCreateModal}>
                                            Cerrar
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="shadow p-3 bg-white rounded" style={{ padding: '16px', borderRadius: '8px' }}>
                        <Card.Body>
                            <Col>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>

                                    <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                                        <select
                                            type="text"
                                            name="status"
                                            value={formData.status || ''}
                                            disabled={!editForm}
                                            className="form-select"
                                            id="floatingStatus"
                                            placeholder=" "
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecciona un Estatus</option>
                                            <option value="Inicio">Inicio</option>
                                            <option value="En proceso">En proceso</option>
                                            <option value="Detenido">Detenido</option>
                                            <option value="Folio Pendiente">Folio Pendiente</option>
                                            <option value="Documentacion adicional">Documentacion adicional</option>
                                            <option value="Finalizado">Finalizado</option>
                                            <option value="Otro">Otro</option>
                                        </select>
                                        <label htmlFor="floatingStatus">Estatus</label>
                                    </div>
                                    <div className="mb-3" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                                        <div className="form-floating">
                                            <div
                                                className="form-control"
                                                id="floatingDaysElapsed"
                                                style={{
                                                    backgroundColor: calculateDaysElapsed(data.created_at).color,
                                                    borderRadius: '0.25rem',
                                                    color: '#fff' // Blanco para el texto
                                                }}
                                            >
                                                {calculateDaysElapsed(data.created_at).days}
                                            </div>
                                            <label htmlFor="floatingDaysElapsed">Días transcurridos</label>
                                        </div>
                                    </div>

                                </div>
                                <div >
                                    {changes && (
                                        <div style={{ marginTop: "10px" }}>
                                            <Card style={{ width: '100%', maxWidth: '600px', height: '100%' }}>
                                                <Card.Header>Bitacora</Card.Header>
                                                <Card.Body>
                                                    <div style={{ maxHeight: '30%', overflowY: 'scroll' }}>
                                                        {changes.map((log, index) => (
                                                            <div key={index} style={{ marginBottom: '15px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
                                                                <strong>Campo:</strong> {log.field_changed} <br />
                                                                <strong>Valor Antiguo:</strong> {log.old_value} <br />
                                                                <strong>Nuevo Valor:</strong> {log.new_value} <br />

                                                                <strong>Fecha:</strong> {new Date(log.changed_at).toLocaleDateString()} <br />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                    <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                                        <input
                                            type="text"
                                            name="created_at"
                                            value={data.created_at || ''}
                                            disabled={!editForm}
                                            className="form-control"
                                            id="floatingCreated"
                                            placeholder=" "
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="floatingCreated">Creacion</label>
                                    </div>
                                    <div className="mb-3 form-floating" style={{ flex: '1 1 30px', fontSize: '12px' }}>
                                        <input
                                            type="text"
                                            name="updated_at"
                                            value={data.updated_at || ''}
                                            disabled={!editForm}
                                            className="form-control"
                                            id="floatingUpdate"
                                            placeholder=" "
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="floatingUpdate">Ultima Modificacion</label>
                                    </div>
                                </div>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <TestComponent endorsementsData={rows} />
            <Card className="shadow p-3 bg-white rounded mt-5" style={{ padding: '16px', borderRadius: '8px' }}>
                <Card.Body>
                    <h5><strong>Ver Asegurados</strong></h5>


                    <TableInsuredProcedure procedure={data?.insured_procedures} idProcedure={data?.id_procedure} />
                    <hr />
                </Card.Body>
            </Card>


        </div >
    );

}
export default ProcedureDetails;
