import React, { useState, useEffect } from 'react';
import { getRole, getUsers, updateUsers } from '../../src/api/userService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { Modal, Button, Form } from 'react-bootstrap';

const MySwal = withReactContent(Swal);

function UserList() {

  const [users, setUsers] = useState([]); // Estado para almacenar los datos de las aseguradoras
  const [editingId, setEditingId] = useState(null); // Estado para el ID del seguro que se está editando
  const [originalData, setOriginalData] = useState({}); // Estado para almacenar los datos originales del seguro
  const [updatedData, setUpdatedData] = useState({}); // Estado para almacenar los datos actualizados del seguro
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [role, setRole] = useState('')
  const canDeleteOrCreate = role === 'admin' || role === 'executive';

  useEffect(() => {
    fetchUsers();
    fetchRole()
  }, []);
  const fetchRole = async () => {
    try {
      const role = await getRole()
      setRole(role)
      console.log("Role", role)
    } catch (error) {
      console.error("Error en GetRole")
    }
  }

  const fetchUsers = async () => {
    try {
      const data = await getUsers(); // Obtiene los datos de las aseguradoras
      setUsers(data); // Actualiza el estado con los datos obtenidos
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const userToEdit = users.find(user => user.id === id);
    setOriginalData(userToEdit); // Al iniciar la edición, guarda los datos originales del seguro
    setFormData({ ...userToEdit });
    setShowModal(true);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setFormData({});
    setShowModal(false);
    setEdit(false);
  };
  const handleEditSecundary = () => {
    setEditingId();
    setEdit(true);
  };


  const handleSaveEdit = async () => {
    try {
      await updateUsers(editingId, formData); // Asegúrate de que formData esté completo y correcto
      setEditingId(null);
      setFormData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(fetchUsers); // Asegúrate de que fetchInsurers esté actualizando correctamente la lista
      setShowModal(false);
    } catch (error) {
      console.error('Error updating User:', error);
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        title: '¡Error!',
        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };





  // Función para manejar cambios en los datos actualizados del seguro
  const handleFieldChange = (fieldName, value) => {
    setUpdatedData({ ...updatedData, [fieldName]: value });
  };
  const handleDelete = async (id) => {
    // Swal.fire({
    //   title: '¿Quieres borrar este Usuario?',
    //   showDenyButton: true,
    //   confirmButtonText: 'Si',
    //   customClass: {
    //     actions: 'my-actions',
    //     cancelButton: 'order-1 right-gap',
    //     confirmButton: 'order-2',
    //     denyButton: 'order-3',
    //   },
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     try {
    //       await deleteUser(id);
    //       setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
    //       MySwal.fire({
    //         icon: 'success',
    //         title: '¡Éxito!',
    //         text: 'EL usuario se ha eliminado correctamente.',
    //       }).then(fetchUsers);
    //     } catch (error) {
    //       console.error('Error deleting user:', error);
    //       MySwal.fire({
    //         icon: 'error',
    //         title: '¡Error!',
    //         text: 'Ha ocurrido un error al eliminar el usuario.',
    //       });
    //     }
    //   }
    // });
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Primer Nombre</th>
            <th scope="col">Correo</th>
            <th scope="col">Correo Trabajo</th>
            <th scope="col">CURP</th>
            <th scope="col">RFC</th>
            <th scope="col">Tel Perso</th>
            <th scope="col">Tel Oficina</th>
            <th scope="col">Nombre empresa</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.first_name}</td>
              <td>{user.email}</td>
              <td>{user.work_email}</td>
              <td>{user.curp}</td>
              <td>{user.rfc}</td>
              <td>{user.phone_personal}</td>
              <td>{user.phone_office}</td>
              <td>{user.company_name}</td>
              <td>
                <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(user.id)}>Ver Completo</button>
                {canDeleteOrCreate && (
                  <button className="btn btn-danger btn-sm" onClick={() => handleDelete(user.id)}>
                    Borrar
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={showModal} onHide={handleCancelEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group controlId="formFirstName">
              <Form.Label>Primer Nombre</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.first_name}
                disabled={!edit}
                onChange={(e) => handleFieldChange('first_name', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.last_name_paternal}
                disabled={!edit}
                onChange={(e) => handleFieldChange('last_name_paternal', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formMaternalLastName">
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.last_name_maternal}
                disabled={!edit}
                onChange={(e) => handleFieldChange('last_name_maternal', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBirthDate">
              <Form.Label>Fecha de Nacimiento</Form.Label>
              <Form.Control
                type="date"
                defaultValue={formData.date_birth}
                disabled={!edit}
                onChange={(e) => handleFieldChange('date_birth', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                defaultValue={formData.email}
                disabled={!edit}
                onChange={(e) => handleFieldChange('email', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formWorkEmail">
              <Form.Label>Correo Trabajo</Form.Label>
              <Form.Control
                type="email"
                defaultValue={formData.work_email}
                disabled={!edit}
                onChange={(e) => handleFieldChange('work_email', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formRFC">
              <Form.Label>RFC</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.rfc}
                disabled={!edit}
                onChange={(e) => handleFieldChange('rfc', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formCURP">
              <Form.Label>CURP</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.curp}
                disabled={!edit}
                onChange={(e) => handleFieldChange('curp', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="tel"
                defaultValue={formData.phone_personal}
                disabled={!edit}
                onChange={(e) => handleFieldChange('phone_personal', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formOfficePhone">
              <Form.Label>Tel Oficina</Form.Label>
              <Form.Control
                type="tel"
                defaultValue={formData.phone_office}
                disabled={!edit}
                onChange={(e) => handleFieldChange('phone_office', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formCompanyName">
              <Form.Label>Nombre empresa</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.company_name}
                disabled={!edit}
                onChange={(e) => handleFieldChange('company_name', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPosition">
              <Form.Label>Posicion</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.position}
                disabled={!edit}
                onChange={(e) => handleFieldChange('position', e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formEmployeeNumber">
              <Form.Label># Empleado</Form.Label>
              <Form.Control
                type="text"
                disabled={!edit}
                defaultValue={formData.employee_number}
                onChange={(e) => handleFieldChange('employee_number', e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCancelEdit}>
            Cancelar
          </Button>
          
          {canDeleteOrCreate && (
            <Button variant="primary" onClick={handleEditSecundary}>
            Editar
          </Button>
          )}
          {edit && (
            <Button variant="success" onClick={handleSaveEdit}>
              Guardar
            </Button>
          )}

        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default UserList
