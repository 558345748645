import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { addEmailToInsured } from '../../../api/InsuredService';

const ModalAddEmail = ({ showModal, handleClose, insured, updateList}) => {
    const MySwal = withReactContent(Swal);
    const [formData, setFormData] = useState({ email: '', work_email: ''});
    const [type, setType] = useState(''); 
    React.useEffect(() => {
       console.log('Carga', insured)
    }, []);

    const handleChangeType = (e) => {
        setType(e.target.value);
        setFormData({email: '', work_email: '' });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            //console.log(formData)
            await addEmailToInsured(insured.id_insured, formData); 

            MySwal.fire({
                title: '¡Éxito!',
                text: 'El email se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Cerrar'
            }).then(()=> {
                updateList();
            });
            handleClose(); // Cerrar el modal después del éxito
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar el email.',
                footer: `<pre>${error.response.data.exception|| 'Error desconocido'}</pre>`,
            });
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Añadir Email a {insured?.complete_name || "No funciona"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                        <div className="form-floating mb-3" style={{ fontSize: '13px' }}>
                            <select
                                name="type"
                                onChange={handleChangeType}
                                className="form-select"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona que correo añadir</option>
                                <option value="email">Personal</option>
                                <option value="work_email">Laboral</option>
                            </select>
                            <label htmlFor="type">Correo</label>
                        </div>
                        {type == 'email' && (
                            <div className="form-floating mb-3">
                                <input
                                    className="form-control"
                                    style={{ fontSize: '13px' }}
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    required
                                />
                                <label>Introduce el Correo Personal:</label>
                            </div>
                        )}
                        {type == 'work_email' && (
                            <div className="form-floating mb-3">
                                <input
                                    className="form-control"
                                    style={{ fontSize: '13px' }}
                                    type="work_email"
                                    name="work_email"
                                    onChange={handleChange}
                                    required
                                />
                                <label>Introduce el Correo Laboral:</label>
                            </div>
                        )}

                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Añadir
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAddEmail;
