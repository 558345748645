import React, { useState, useEffect } from 'react';
import { getHolders, updateHolder, deleteHolder, getHolderByOrganization } from '../../../api/PolicyHolderService';
import { getPoliciesByHolder } from '../../../api/policyService';

import { deleteSubgroup, getSubgroupByPolicyId, updateSubgroup } from "../../../api/SubgroupService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SubgroupForm from '../../SubgroupForm';
import { deletePDF } from '../../../api/PdfService';


import { fetchPools, infoGlobal } from '../../../global';
import { useDispatch, useSelector } from 'react-redux';
import { getPools } from '../../../features/pools/poolService';
import PoolForm from './PoolForm';

const MySwal = withReactContent(Swal);

function PoolList() {
    const dispatch = useDispatch();
    const organization = useSelector((state) => state.organizations.data);
    const user = useSelector((state) => state.users.data);
    const pools = useSelector((state)=> state.pool.pools);
    const canDeleteOrCreate = user === 'admin' || user === 'executive';

    const [poolData, setPoolData] = useState([]);

    const [formData, setFormData] = useState({});

    const [editingId, setEditingId] = useState(null);
    const [originalData, setOriginalData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [edit, setEdit] = useState(false);
    const [role] = useState('')
    
    useEffect(() => {
        fetchPools()
    }, [user,pools, dispatch]);

    const fetchPools = async () => {
        try {
          const data = await getPools();
          if (data) {
            setPoolData(data);
            console.log(data)
          }
        } catch (error) {
          console.error('Error fetching role:', error);
        }
      };
    const handleEdit = (id) => {
        setEditingId(id);
        const poolToEdit = poolData.find((poolData) => poolData.id === id);
        setFormData({ ...poolToEdit });
    };

    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };
    const handleCancelEdit = () => {
        setEditingId(null);
        setFormData({});
        setEdit(false);
    };
    const handleEditSecundary = () => {
        console.log("DAtos subgroup", formData)
        setEdit(true);
    };
    const handleSaveEdit = async () => {
        try {
            console.log("edditingID", editingId)
            await updateSubgroup(editingId, updatedData);
            setEditingId(null);
            setFormData({});
            MySwal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Los datos se han actualizado correctamente.',
            }).then(fetchPools);
            setEdit(false);

        } catch (error) {
            console.error('Error updating Subgroup:', error);
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar los datos.',
            });
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Quieres borrar este Subgrupo?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteSubgroup(id);
                    setPoolData(prev => prev.filter(poolData => poolData.id !== id));
                    MySwal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'El subgrupo se ha eliminado correctamente.',
                    }).then(fetchPools);
                } catch (error) {
                    console.error('Error deleting policy:', error.response.data.message);
                    MySwal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        html: `Ha ocurrido un error al eliminar El subgrupo.<br/><strong>${error.response.data.message}</strong>`,
                    });

                }
            }
        });
    };



    const handleDeletePDF = async (id, pdfId) => {
        try {
            await deletePDF(pdfId);
            console.log(`Eliminar PDF ${pdfId} de la poliza ${id}`);
            alert("anadir el recargar las polizas")
        } catch (error) {
            console.error('Error deleting PDF:', error);
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al eliminar el PDF.',
            });
        }
    };
    const handleFieldChange = (fieldName, value) => {
        setUpdatedData((prevData) => ({ ...prevData, [fieldName]: value }));
    };


    return (
        <div>
            <div className="w-100 px-3 mx-auto">
                {canDeleteOrCreate && <PoolForm />}

                <div className="row">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">ID del pool</th>
                                <th scope="col">Nombre </th>
                                <th scope="col">Acciones </th>
                            </tr>
                        </thead>
                        <tbody>
                            {poolData.map((pool) => (
                                <tr key={pool.id}>
                                    <td>{pool.id}</td>
                                    <td>{pool.name}</td>
                                    <td>
                                        <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(pool.id)}>Ver Completo</button>
                                        {canDeleteOrCreate && (
                                            <button className="btn btn-danger btn-sm" onClick={() => handleDelete(pool.id)}>Borrar</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
}


//Estilos
const dropzoneStyle = {
    border: '2px dashed #6c757d', /* Default border color (secondary) */
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '10px'
};

const activeDropzoneStyle = {
    ...dropzoneStyle,
    borderColor: '#007bff' /* Border color when active (primary) */
};

export default PoolList;
