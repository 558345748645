import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');

export const deletePDF = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/pdf/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del deletePDF:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting PDF:', error);
    throw error;
  }
};
export const disAttachPDF = async (id, pdfableId) => {
  try {
    const response = await axios.delete(`${apiUrl}/disAttachPdf/${id}/${pdfableId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del deletePDF:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting PDF:', error);
    throw error;
  }
};
//pdf/{id}/{table}
export const uploadPDF = async (id, table, data) => {
  try {
    console.log(`La informacion a mandar: ${data}`)

    const response = await axios.post(`${apiUrl}/pdf/${id}/${table}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del uploadPDF:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting PDF:', error);
    throw error;
  }
};

export const uploadFiles = async (data) => {
  try {
    console.log(`La informacion a mandar: ${data}`)

    const response = await axios.post(`${apiUrl}/upload-files`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta del uploadPDF:", response.data);
    return response.data;

  } catch (error) {
    console.error('Error uploading File:', error);
    throw error;
  }
};