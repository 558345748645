import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { postHolder } from '../api/PolicyHolderService';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../global';
import { RenderDropzone } from '../features/pdfs/functionsPdf';
import { Button, Modal, Form, Col, Row, Accordion } from 'react-bootstrap';

const MySwal = withReactContent(Swal);

function PolicyHolderForm({ buttonAddForeign, organizationForeign, onClose }) {
  const dispatch = useDispatch();
  const [orgDatas, setOrgDatas] = useState([]);
  const [orgDatasFiltered, setOrgDatasFiltered] = useState([]);
  const organization = useSelector((state) => state.organizations.data);

  const [buttonAdd, setButtonAdd] = useState(false);
  const [formData, setFormData] = useState({ business_name: '', rfc: '', fiscal_address: '', organization_id: '', logo: '', entityType: '' });
  const [pdfData, setPdfData] = useState([]);

  const categories = {
    //Fisica
    'pdf_rfc': 'RFC',
    //Moral
    'pdf_fiscalStatusDocument': 'CSF',
    'pdf_constitutiveAct': 'Acta Constitutiva',
    'pdf_powers': 'Poder',
    //Ambos
    'pdf_residenceProof': 'Comprobante de Domicilio',
    'pdf_identification': 'Identificacion',
    'pdf_others': 'Otros',
  };
  useEffect(() => {

    if (!organization.length) {
      infoGlobal.fetchOrganizations();
      console.log('organization1', organization)
    } else {
      setOrgDatas(organization)
      console.log('organization2', organization)
    }
    if (organizationForeign) {
      console.log("organizationForeign", organizationForeign)
      setFormData((prevState) => ({
        ...prevState,
        organization_id: organizationForeign,
      }));
    }
  }, [organization, dispatch, organizationForeign, buttonAddForeign]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Convertir a mayúsculas solo para campos específicos
    const upperCaseFields = ['rfc', 'business_name', 'fiscal_address'];
    const newValue = upperCaseFields.includes(name) ? value.toUpperCase() : value;

    // Actualizar el estado
    setFormData({ ...formData, [name]: newValue });
  };
  const handleImageChange = (event) => {
    console.log("img", event.target.files[0])
    setFormData({ ...formData, logo_path: event.target.files[0] });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData();

    // Agregar datos del formulario
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    // Validar archivos PDF dentro del form
    if (pdfData.length === 0) {
      alert("No hay PDFs para subir");
      return;
    }
    let hasInvalidCategory = false;

    pdfData.forEach((data) => {
      if (data.category === 'Selecciona' || !data.category) {
        hasInvalidCategory = true; // Marca si hay alguna categoría no seleccionada
      } else {
        form.append(`${data.category}[]`, data.file); // Agrega los archivos con categorías al form
      }
    });
    // Si alguna categoría es inválida, se muestra la alerta y se cancela el envío
    if (hasInvalidCategory) {
      alert("Asegúrate de seleccionar una categoría para todos los archivos.");
      return;
    }


    try {
      await postHolder(form);
      setPdfData([]);

      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'La Organización se ha añadido correctamente.',
      }).then(() => {
        setButtonAdd(false); // Después de enviar el formulario, cambia el estado de botonAdd a false
        if (onClose) {
          onClose();
        }
        setFormData({ business_name: '', rfc: '', fiscal_address: '', organization_id: '', logo_path: '', });
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: `Hubo un problema al añadir la organización. Inténtalo de nuevo.${error}`,
      });
    }
  };

  const handleFileChange = (event, updatedFiles, category) => {
    const selectedFiles = Array.from(event.target.files);
    const updatedPdfData = selectedFiles.map((file) => ({
      file,
      category: categories[0],
    }));
    setPdfData((prevPdfData) => [...prevPdfData, ...updatedPdfData]);

    // setFormData(prevFormData => ({
    //   ...prevFormData,
    //   pdfs: updatedFiles
    // }));
    // console.log(category, updatedFiles);
  };
  const handleCategoryChange = (index, selectedCategory) => {
    const updatedPdfData = [...pdfData];
    updatedPdfData[index].category = selectedCategory;
    setPdfData(updatedPdfData);
  };

  const handleRemoveFile = (index) => {
    const updatedPdfData = [...pdfData];
    updatedPdfData.splice(index, 1); // Eliminar el archivo en el índice dado
    setPdfData(updatedPdfData);
  };

  const handleOnClose = () => {
    if (buttonAddForeign) {
      onClose()
    } else {
      setButtonAdd(false)
    }
  }


  if (buttonAdd || buttonAddForeign) {

    return (
      <Modal dialogClassName="modal-xl" show={true} onHide={() => handleOnClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Contratante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="container mt-4">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="form-floating">
                  {orgDatasFiltered.length < 1 ? (
                    <select
                      name="organization_id"
                      value={formData.organization_id}
                      onChange={handleChange}
                      className="form-select"
                      id="floatingOrgSelect"
                    >
                      <option value="">Selecciona una organización</option>
                      {orgDatas.map(org => (
                        <option key={org.id_organization} value={org.id_organization}>
                          {org.id_organization} - {org.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      name="organization_id"
                      value={`${orgDatasFiltered[0].id_organization} - ${orgDatasFiltered[0].name}`}
                      readOnly
                      className="form-control"
                      id="floatingOrgSelect"
                    />
                  )}
                  <label htmlFor="floatingOrgSelect">Organización</label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                    className="form-control"
                    id="floatingBusinessName"
                    placeholder=" "
                  />
                  <label htmlFor="floatingBusinessName">Nombre</label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    name="rfc"
                    value={formData.rfc}
                    onChange={handleChange}
                    className="form-control text-uppercase" // Clase para mayúsculas
                    id="floatingRFC"
                    placeholder=" "
                  />
                  <label htmlFor="floatingRFC">RFC</label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    name="fiscal_address"
                    value={formData.fiscal_address}
                    onChange={handleChange}
                    className="form-control text-uppercase" // Clase para mayúsculas
                    id="floatingFiscalAddress"
                    placeholder=" "
                  />
                  <label htmlFor="floatingFiscalAddress">Direccion Fiscal</label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-floating">
                  <select
                    type="text"
                    name="entityType"
                    value={formData.entityType}
                    onChange={handleChange}
                    className="form-select"
                    id="floatingEntityType"
                    placeholder=" "
                  >
                    <option value="">Selecciona Persona</option>
                    <option value="Persona Fisica">Persona Fisica</option>
                    <option value="Persona Moral">Persona Moral</option>
                  </select>
                  <label htmlFor="floatingEntityType">Entidad: </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-floating">
                  <input
                    type="file"
                    id="logo_path"
                    name="logo_path"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="form-control"
                    style={{ paddingTop: '30px'}}

                  />
                  <label htmlFor="logo_path">Logo</label>
                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="mb-3">
                  <RenderDropzone
                    onFilesChange={handleFileChange}
                    fileName='Acta Constitutiva'
                    category="pdf_constitutiveAct"
                    files={formData.pdf_constitutiveAct}
                  />
                </div>
                <div className="mb-3">
                  <RenderDropzone
                    onFilesChange={handleFileChange}
                    fileName='Constancia de Situacion Fiscal'
                    category="pdf_fiscalStatusDocument"
                    files={formData.pdf_fiscalStatusDocument}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <RenderDropzone
                    onFilesChange={handleFileChange}
                    fileName='Comprobante de Domicilio'
                    category="pdf_residenceProof"
                    files={formData.pdf_residenceProof}
                  />
                </div>
                <div className="mb-3">
                  <RenderDropzone
                    onFilesChange={handleFileChange}
                    fileName='Identificación'
                    category="pdf_identification"
                    files={formData.pdf_identification}
                  />
                </div>
                <div className="mb-3">
                  <RenderDropzone
                    onFilesChange={handleFileChange}
                    fileName='Otros'
                    category="pdf_others"
                    files={formData.pdf_others}
                  />
                </div>
              </div> */}

            </div>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  {/* <RenderDropzone
                  onFilesChange={handleFileChange}
                  fileName="Poliza PDF"
                  category="Policy"
                  files={formData.pdfs}
                /> */}
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Archivos</Accordion.Header>
                      <Accordion.Body>

                        <input
                          class="form-control"
                          type="file"
                          accept=".pdf"
                          multiple
                          onChange={handleFileChange}
                        />
                        <br />
                        {pdfData && (
                          <div>
                            {pdfData?.map((pdf, index) => (
                              <div key={index} style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {/* Texto */}
                                  <p style={{
                                    margin: 0,
                                    marginRight: '10px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '90%'
                                  }}>
                                    {pdf.file.name}
                                  </p>
                                  {/* Select */}
                                  <select
                                    value={pdf.category}
                                    className="form-select"
                                    style={{ marginRight: '10px' }}
                                    onChange={(e) => handleCategoryChange(index, e.target.value)}
                                  >
                                    <option value="">Selecciona</option>
                                    {Object.entries(categories).map(([key, value], i) => (
                                      <option key={i} value={key}>
                                        {value}
                                      </option>
                                    ))}
                                  </select>

                                  {/* Botón */}
                                  <button
                                    type="button"
                                    className='btn btn-danger'
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    Eliminar
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer>
              <Button type="button" className="btn btn-danger" onClick={() => handleOnClose()}>Cancelar</Button>
              <Button type="submit" className="btn btn-primary me-3">Guardar</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }

  if (!buttonAdd && !buttonAddForeign) {
    return (
      <div>
        {!buttonAdd && (
          <div>
            <div className="mb-4">
              <button className="btn btn-success w-100" onClick={() => setButtonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <h2>Contratante</h2>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="form-floating">
            <select
              name="organization_id"
              value={formData.organization_id}
              onChange={handleChange}
              className="form-select"
              id="floatingOrgSelect"
            >
              <option value="">Selecciona una organización</option>
              {orgDatas.map(org => (
                <option key={org.id_organization} value={org.id_organization}>{org.id_organization} - {org.name}</option>
              ))}
            </select>
            <label htmlFor="floatingOrgSelect">Organización</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating">
            <input
              type="text"
              name="business_name"
              value={formData.business_name}
              onChange={handleChange}
              className="form-control"
              id="floatingBusinessName"
              placeholder=" "
            />
            <label htmlFor="floatingBusinessName">Nombre</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating">
            <input
              type="text"
              name="rfc"
              value={formData.rfc}
              onChange={handleChange}
              className="form-control text-uppercase" // Clase para mayúsculas
              id="floatingRFC"
              placeholder=" "
            />
            <label htmlFor="floatingRFC">RFC</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating">
            <input
              type="text"
              name="fiscal_address"
              value={formData.fiscal_address}
              onChange={handleChange}
              className="form-control text-uppercase" // Clase para mayúsculas
              id="floatingFiscalAddress"
              placeholder=" "
            />
            <label htmlFor="floatingFiscalAddress">Direccion Fiscal</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating">
            <select
              type="text"
              name="entityType"
              value={formData.entityType}
              onChange={handleChange}
              className="form-select"
              id="floatingEntityType"
              placeholder=" "
            >
              <option value="">Pendiente</option>
              <option value="Persona Fisica">Persona Fisica</option>
              <option value="Persona Moral">Persona Moral</option>
            </select>
            <label htmlFor="floatingEntityType">Entidad: </label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="mb-3">
            <label htmlFor="logo_path">Logo de la Aseguradora</label>

            <input
              type="file"
              id="logo_path"
              name="logo_path"
              accept="image/*"
              onChange={handleImageChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="pdf_constitutiveAct" className="form-label">Acta Constitutiva</label>
            <input
              type="file"
              id="pdf_constitutiveAct"
              accept="application/pdf"
              multiple
              onChange={(e) => handleFileChange(e, 'pdf_constitutiveAct')}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pdf_fiscalStatusDocument" className="form-label">Constancia de Situacion Fiscal</label>
            <input
              type="file"
              id="pdf_fiscalStatusDocument"
              accept="application/pdf"
              multiple
              onChange={(e) => handleFileChange(e, 'pdf_fiscalStatusDocument')}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="pdf_residenceProof" className="form-label">Comprobante de Domicilio</label>
            <input
              type="file"
              id="pdf_residenceProof"
              accept="application/pdf"
              onChange={(e) => handleFileChange(e, 'pdf_residenceProof')}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pdf_identification" className="form-label">Identificación</label>
            <input
              type="file"
              id="pdf_identification"
              accept="application/pdf"
              multiple
              onChange={(e) => handleFileChange(e, 'pdf_identification')}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pdf_others" className="form-label">Otros</label>
            <input
              type="file"
              id="pdf_others"
              accept="application/pdf"
              multiple
              onChange={(e) => handleFileChange(e, 'pdf_others')}
              className="form-control"
            />
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-primary">Guardar</button>
      <button type="button" className="btn btn-danger" onClick={() => setButtonAdd(false)}>Cancelar</button>
    </form>
  );

}

export default PolicyHolderForm;
