import React, { useState, useEffect } from 'react';
import { deletePolicy, getPolicies, updatePolicy } from '../api/policyService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getOrganizations } from '../api/OrganizationService';
import { getHolderByOrganization, getPoliciesByHolder } from '../api/PolicyHolderService';
import { Modal, Button, Form } from 'react-bootstrap';
import { deletePDF } from '../api/PdfService';

import { pdfLogo } from "../global";
import { getRole } from '../api/userService';
import PolicyHolderForm from './PolicyHolderForm';
import PolicyForm from './PolicyForm';
import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
import { getPools } from '../features/pools/poolService';
import SearchOrganization from './SearchAutoComplete/Organization/SearchOrganization';

import ModalEditPolicie from "./Policies/ModalEditPolicies";

const MySwal = withReactContent(Swal);
function PoliciesList() {
    const dispatch = useDispatch();
    const organization = useSelector((state) => state.organizations.data);
    const user = useSelector((state) => state.users.data);
    const [policies, setPolicies] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [originalData, setOriginalData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [policyHolders, setPolicyHolders] = useState([]);
    const [policyNumber, setPolicyNumber] = useState('');
    const [dataFetched, setDataFetched] = useState(false);
    const [orgDatas, setOrgDatas] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedPolicyHolder, setSelectedPolicyHolder] = useState('');
    const [policyToEdit, setPolicyToEdit] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [edit, setEdit] = useState(false);
    const [role] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditModalOpen = () => setShowEditModal(true);
    const handleEditModalClose = () => { setShowEditModal(false); }

    const canDeleteOrCreate = user === 'admin' || user === 'executive';

    useEffect(() => {
        if (!organization.length) {
            infoGlobal.fetchOrganizations();
        } else {
            setOrgDatas(organization)
        }
        if (!user.length) {
            infoGlobal.fetchRole();
        }
    }, [organization, user, dispatch]);


    // const handleOrganizationChange = async (event) => {
    //     try {
    //         const selectedOrgId = event.target.value;
    //         console.log("selectedOrgId", selectedOrgId);
    //         setSelectedOrganization(selectedOrgId);
    //         //obtener los policyHOLder dado el id de la organizacion

    //     } catch (error) {
    //         console.error('Error handling organization change:', error);
    //     }
    // };
    const handleOrganizationChange = async (selectedOrgId) => {
        try {
            setSelectedOrganization(selectedOrgId);
            console.log("ID DESDE ORG>JS PRUEBNA", selectedOrgId)
            const data = await getHolderByOrganization(selectedOrgId);
            setPolicyHolders(data.holders);
        } catch (error) {
            console.error(error)
        }
    };
    const handlePolicyHolderChange = async (event) => {
        try {
            const selectedPolicyHolder = event.target.value;
            console.log("selectedPolicyHolder", selectedPolicyHolder);
            setSelectedPolicyHolder(selectedPolicyHolder);
            if (selectedPolicyHolder) {
                //obtener las polizas dado el id del policy holder
                const data = await getPoliciesByHolder(selectedPolicyHolder);
                console.log("Data de getPoliciesByHolder", data)
                setPolicies(data);
                setDataFetched(true);
            } else {
                setDataFetched(false);
            }
        } catch (error) {
            console.error('Error handling policyHolder change:', error);
        }
    };

    const fetchPolicies = async () => {
        if (policyNumber) {
            try {
                const data = await getPolicies(policyNumber);
                setPolicies(data);
                setDataFetched(true);
            } catch (error) {
                console.error('Error fetching policies:', error);
            }
        } else if (selectedPolicyHolder) {
            const data = await getPoliciesByHolder(selectedPolicyHolder);
            setPolicies(data);
        }
    };
    const handleSelectPolicy = (policy) => {
        setPolicyToEdit(policy)
        handleEditModalOpen()
    }
    const handleEdit = (id) => {
        setEditingId(id);
        const policyToEdit = policies.find((policy) => policy.id_policy === id);
        setFormData({ ...policyToEdit });
        setShowModal(true);
    };
    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };
    const handleCancelEdit = () => {
        setEditingId(null);
        setFormData({});
        setEdit(false);
    };
    const handleEditSecundary = () => {
        setEdit(true);
    };

    const handleSaveEdit = async () => {
        try {
            console.log("edditingID", editingId)
            await updatePolicy(editingId, updatedData);
            setEditingId(null);
            setFormData({});
            MySwal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Los datos se han actualizado correctamente.',
            }).then(fetchPolicies);
            setShowModal(false);
            setEdit(false);

        } catch (error) {
            console.error('Error updating Policy:', error);
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar los datos.',
            });
        }
    };
    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Quieres borrar la Poliza?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deletePolicy(id);
                    setPolicies(prev => prev.filter(policies => policies.id_policy !== id));
                    MySwal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'La Poliza se ha eliminado correctamente.',
                    }).then(fetchPolicies);
                } catch (error) {
                    console.error('Error deleting policy:', error.response.data.message);
                    MySwal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        html: `Ha ocurrido un error al eliminar la Poliza.<br/><strong>${error.response.data.message}</strong>`,
                    });

                }
            }
        });
    };
    const handleDeletePDF = async (id, pdfId) => {
        try {
            await deletePDF(pdfId);
            console.log(`Eliminar PDF ${pdfId} de la poliza ${id}`);
            alert("anadir el recargar las polizas")
        } catch (error) {
            console.error('Error deleting PDF:', error);
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al eliminar el PDF.',
            });
        }
    };
    const handleClose = async () => {
        setShowModal(false)
    }
    const handleFieldChange = (fieldName, value) => {
        setUpdatedData((prevData) => ({ ...prevData, [fieldName]: value }));
    };


    return (
        <div>
            <div className="px-3 mx-auto">
                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end">
                        <div className="input-group mb-3">
                            <h2 className="me-3">Polizas</h2>
                            <span className="input-group-text" id="inputGroup-sizing-default">Póliza</span>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={policyNumber}
                                onChange={(e) => setPolicyNumber(e.target.value)}
                                placeholder="Ingrese el número de póliza"
                            />
                            <button className="btn btn-primary" onClick={fetchPolicies}>Buscar</button>
                        </div>
                    </div>
                </div>




                {canDeleteOrCreate && <PolicyForm updateData={fetchPolicies} />}
                <div className="row">

                    <div className="col-md-6">
                        <SearchOrganization onSelect={handleOrganizationChange} />
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <select
                                name="PolicyHolder_id"
                                value={selectedPolicyHolder}
                                onChange={handlePolicyHolderChange}
                                className="form-control"
                                id="PolicyHolder_id"
                            >
                                <option value="">Selecciona un Contratante</option>
                                {policyHolders.map((holder) => (
                                    <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                                        {holder.id_policy_holder} - {holder.business_name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="PolicyHolder_id">Contratante</label>

                        </div>
                    </div>
                </div>
                {dataFetched && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Número de Póliza</th>
                                <th scope="col">Tipo de Póliza</th>
                                <th scope="col">Rama</th>
                                <th scope="col">Fecha de Inicio de Cobertura</th>
                                <th scope="col">Fecha de Fin de Cobertura</th>
                                <th scope="col">Prima Neta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {policies.map((policy) => (
                                <tr key={policy.id_policy}>
                                    <td>{policy.policy_number}</td>
                                    <td>{policy.policy_type}</td>
                                    <td>{policy.policy_branch}</td>
                                    <td>{policy.start_date_coverage}</td>
                                    <td>{policy.end_date_coverage}</td>
                                    <td>{policy.net_premium}</td>
                                    {/* <td>
                                        {policy.pdfs.map((pdf, index) => (
                                            <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                                <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                    <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                                                </a>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    style={{ position: 'absolute', top: '0', right: '0', padding: '0.2rem' }}
                                                    onClick={() => handleDeletePDF(policy.id_policy, pdf.id)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </td> */}
                                    <td>
                                        <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(policy.id_policy)}>Ver Completo</button>
                                        <button className="btn btn-primary btn-sm me-1" onClick={() => handleSelectPolicy(policy)}>Mostrar Modal</button>
                                        {canDeleteOrCreate && (<button className="btn btn-danger btn-sm" onClick={() => handleDelete(policy.id_policy)}>Borrar</button>
                                        )}
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {
                    showEditModal && (
                        <ModalEditPolicie
                            data={policyToEdit}
                            showModal={true}
                            closeModal={handleEditModalClose}
                        />
                    )
                }
                <Modal show={showModal} onHide={handleClose} dialogClassName="modal-xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Poliza</Modal.Title>

                    </Modal.Header>


                    <Modal.Body>
                        <Form>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <Form.Group controlId="formPolicyNumber">
                                        <Form.Label>Número de Póliza</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.policy_number}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('policy_number', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPolicyNumber">
                                        <Form.Label>Administrador de la poliza</Form.Label>
                                        <Form.Control
                                            type="email"
                                            defaultValue={formData.admin_email}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('admin_email', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPolicyType">
                                        <Form.Label>Tipo de Póliza</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.policy_type}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('policy_type', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBonus1">
                                        <Form.Label>Bonus 1</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.bonus1}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('bonus1', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBonus2">
                                        <Form.Label>Bonus 2</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.bonus2}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('bonus2', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formComIdPolicy">
                                        <Form.Label>ID de la Poliza</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.com_id_policy}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('com_id_policy', e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-md-3'>

                                    <Form.Group controlId="formCommissionPercentage">
                                        <Form.Label>Porcentaje de Comisión</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.commission_percentage}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('commission_percentage', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formConcept">
                                        <Form.Label>Concepto</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.concept}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('concept', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formEndDateCoverage">
                                        <Form.Label>Fecha de Fin de Cobertura</Form.Label>
                                        <Form.Control
                                            type="date"
                                            defaultValue={formData.end_date_coverage}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('end_date_coverage', e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formFractionalPaymentSurcharge">
                                        <Form.Label>Recargo de Pago Fraccionado</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.fractional_payment_surcharge}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('fractional_payment_surcharge', e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-md-3'>

                                    <Form.Group controlId="formInsuranceType">
                                        <Form.Label>Rama</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.policy_branch}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('policy_branch', e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formNetPremium">
                                        <Form.Label>Prima Neta</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.net_premium}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('net_premium', e.target.value)}
                                        />
                                    </Form.Group>


                                </div>
                                <div className='col-md-3'>

                                    <Form.Group controlId="formPaymentMethod">
                                        <Form.Label>Método de Pago</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.payment_method}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('payment_method', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPolicyFee">
                                        <Form.Label>Cuota de Póliza</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.policy_fee}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('policy_fee', e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formStartDateCoverage">
                                        <Form.Label>Fecha de Inicio de Cobertura</Form.Label>
                                        <Form.Control
                                            type="date"
                                            defaultValue={formData.start_date_coverage}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('start_date_coverage', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formTotalPremium">
                                        <Form.Label>Prima Total</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.total_premium}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('total_premium', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPool">
                                        <Form.Label>Pool</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={formData.pool}
                                            disabled={!edit}
                                            onChange={(e) => handleFieldChange('pool', e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                                {/* Mostrar sección para subir más PDFs */}
                                {/* <Form.Group controlId="formPDFs">
                                    <Form.Label>Cambiar Pdf de la poliza</Form.Label>
                                    <Form.Control type="file" onChange={(e) => handleFieldChange('pdfs', e.target.files)} />
                                </Form.Group> */}
                                <thead>
                                    <tr>
                                        <th>PDF Subgrupo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {formData.pdfs && formData.pdfs.length > 0 ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {formData.pdfs.map((pdf, index) => (
                                                        <div key={index} style={{ textAlign: 'center', marginRight: '20px' }}>
                                                            <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                                <img src={pdfLogo} alt="PDF" style={{ width: '40px' }} />
                                                            </a>
                                                            <br />
                                                            <p className='small'> {pdf.file_name || `PDF ${index + 1}`}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <span>No PDFs available</span>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </Form>
                        <Modal.Footer>
                            {canDeleteOrCreate && (
                                <div className="ms-auto">
                                    {!edit ? (
                                        <Button variant="primary" onClick={handleEditSecundary} className="me-2">
                                            Editar
                                        </Button>
                                    ) : (
                                        <div>
                                            <Button variant="success" onClick={handleSaveEdit} className="me-2">
                                                Guardar
                                            </Button>
                                            <Button variant="danger" onClick={handleCancelEdit}>
                                                Cancelar
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal.Body>

                </Modal>
            </div>
        </div>
    );
}

export default PoliciesList;
