import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Nav, Table } from 'react-bootstrap';
import { attachPDFEndorsement, getInsuredProcedureAsociates, updateEndorsement, updateInsuredProcedures } from '../api/EndorsementService';
import SearchPolicy from '../components/SearchAutoComplete/Policy/SearchPolicy';
import { findPolicyByOrganization } from '../api/OrganizationService';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

function TestComponent({ show, handleClose, endorsementsData, organization }) {
  const [selectedEndoso, setSelectedEndoso] = useState(); // Predeterminado al primer Endoso
  const [associatedProcedures, setAssociatedProcedures] = useState([]);
  const [unassociatedProcedures, setUnassociatedProcedures] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [edit, setEdit] = useState(false);
  const [pdfEndorsement, setPdfEndorsement] = React.useState(new FormData());

  const [formEndorsementData, setFormEndorsementData] = React.useState({
    policy_number: '',
    endorsement_number: '',
    insure_start_date: '',
  });
  const cancelEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    fetchPolicies()
  }, []);
  const fetchPolicies = async () => {
    console.log('endorsementsData', endorsementsData)
    console.log('organization', organization)
    const response = await findPolicyByOrganization(organization)
    console.log("response", response)
    setPolicies(response)
  }
  const enableEditing = () => {
    setEdit(true);
  };
  const handlePolicyChange = (event) => {
    console.log("poliza id", event);
    setSelectedPolicy(event)
    setFormEndorsementData(prevFormData => ({
      ...prevFormData,
      policy_id: event
    }));
  };
  console.log('endorsementData', endorsementsData)
  // Cambiar el endoso seleccionado
  const handleEndosoClick = async (endosoId) => {
    setSelectedEndoso(endosoId); // Cambiar al endoso seleccionado
    const response = await getInsuredProcedureAsociates(endosoId);
    console.log('response', response)
    setAssociatedProcedures(response.associated);
    setUnassociatedProcedures(response.unassociated);
    // Encontrar el endoso seleccionado dentro de los datos de endosos
    const selectedEndorsement = endorsementsData.find(endorsement => endorsement.id_endorsement === endosoId);
    console.log('selectedEndorsement', selectedEndorsement)
    if (selectedEndorsement) {
      setFormEndorsementData({
        endorsement_number: selectedEndorsement.endorsement_number || "",
        insure_start_date: selectedEndorsement.insure_start_date || "",
        pdfs: selectedEndorsement.pdfs || [],
        insured_procedures: selectedEndorsement.insured_procedures || []
      });
    }
  };
  const handleUpdate = async () => {
    const data = {
      associated: associatedProcedures.map((proc) => proc.id),
      unassociated: unassociatedProcedures.map((proc) => proc.id),
    };

    try {
      const response = await updateInsuredProcedures(selectedEndoso, data);
      console.log('Actualización exitosa:', response);
    } catch (error) {
      console.error('Error al actualizar los procedimientos:', error);
    }
  };
  const handleCheckboxChange = (id) => {
    // Verifica si el asegurado está en la lista de asociados o no asociados
    const associatedIndex = associatedProcedures.findIndex((insured) => insured.id === id);
    const unassociatedIndex = unassociatedProcedures.findIndex((insured) => insured.id === id);

    // Si está en la lista de asociados, lo desasocia
    if (associatedIndex !== -1) {
      const insured = associatedProcedures[associatedIndex];
      // Mueve el asegurado a la lista de no asociados
      setAssociatedProcedures((prev) => prev.filter((item) => item.id !== id));
      setUnassociatedProcedures((prev) => [...prev, { ...insured, associated: false }]);
    }
    // Si está en la lista de no asociados, lo asocia
    else if (unassociatedIndex !== -1) {
      const insured = unassociatedProcedures[unassociatedIndex];
      // Mueve el asegurado a la lista de asociados
      setUnassociatedProcedures((prev) => prev.filter((item) => item.id !== id));
      setAssociatedProcedures((prev) => [...prev, { ...insured, associated: true }]);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormEndorsementData({ ...formEndorsementData, [name]: value });

  };
  const saveEndorsement = async () => {

    try {
      // Llamada a la función para actualizar el respaldo
      await updateEndorsement(selectedEndoso, formEndorsementData);
      if (!pdfEndorsement) {
        await attachPDFEndorsement(selectedEndoso, pdfEndorsement);
      }

      setEdit(false)
    } catch (error) {
      console.error("Error al guardar los datos:", error);
    }
  };
  const handleFileEndorsementChange = (event) => {
    const files = Array.from(event.target.files); // Convertimos la lista de archivos en un array
    console.log('Archivos seleccionados:', files);

    // Crear un nuevo FormData y agregar los archivos
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(file); // Agrega cada archivo con un nombre único
    });

    setPdfEndorsement(formData);
  };
  const removeRow = async (id) => {

  };
  return (
    <Modal show={show} onHide={handleClose} dialogClassName='custom-modal-xl'>
      <Modal.Header closeButton>
        <Modal.Title>Selecciona un Endoso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/* Columna para la lista de endosos */}
          <Col xs={2} className="border-right" style={{ borderRight: '3px solid #ccc' }}>
            <Nav className="flex-column">
              <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                {endorsementsData.map((endorsement) => (
                  <Nav.Link
                    key={endorsement.id_endorsement}
                    onClick={() => handleEndosoClick(endorsement.id_endorsement)}
                    active={selectedEndoso === endorsement.id_endorsement}
                    style={{
                      cursor: 'pointer',
                      border: '1px solid #ccc',
                      padding: '10px',
                      marginBottom: '5px',
                      borderRadius: '5px',
                      backgroundColor: selectedEndoso === endorsement.id_endorsement ? '#d1e7dd' : 'transparent',
                      fontWeight: selectedEndoso === endorsement.id_endorsement ? 'bold' : 'normal',
                    }}
                  >
                    Endoso con id: {endorsement.id_endorsement}
                  </Nav.Link>
                ))}
              </div>
            </Nav>
          </Col>

          {/* Columna para los campos del formulario y tablas */}
          <Col xs={10}>
            <div style={{ padding: '15px' }}>
              <h5>
                Información del {endorsementsData.find((e) => e.id_endorsement === selectedEndoso)?.id_endorsement}
              </h5>

              {/* Ajuste de las columnas para el formulario */}
              <Row className="mb-3">
                <Col xs={4}>
                  <SearchPolicy
                    data={policies}
                    onSelect={handlePolicyChange}
                  />
                </Col>
                <Col xs={4}>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="endorsement_number"
                      value={formEndorsementData.endorsement_number}
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      placeholder=" "
                      disabled={!edit}
                    />
                    <label>No de Endoso</label>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="form-floating">
                    <input
                      type="date"
                      name="insure_start_date"
                      value={formEndorsementData.insure_start_date}
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      placeholder=" "
                      disabled={!edit}
                    />
                    <label>Inicio de proceso de la aseguradora</label>
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xs={4}>
                  <div className="form-floating">
                    <input
                      type="file"
                      name="pdf"
                      accept="application/pdf"
                      onChange={handleFileEndorsementChange}
                      className="form-control"
                      placeholder=" "
                      disabled={!edit}
                    />
                    <label>PDFs</label>
                  </div>
                </Col>
              </Row>

              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {!edit ? (
                  <Button
                    type="button"
                    onClick={() => enableEditing()}
                    className="btn btn-primary btn-md"
                  >
                    <EditRoundedIcon />
                  </Button>
                ) : (
                  <>
                    <Button
                      type="button"
                      onClick={saveEndorsement} // Guarda cambios
                      className="btn btn-success btn-md"
                    >
                      <SaveIcon />
                    </Button>
                    <Button
                      type="button"
                      onClick={cancelEdit} // Cancela la edición
                      className="btn btn-secondary btn-md"
                    >
                      <CancelIcon />
                    </Button>
                  </>
                )}
                {!edit && (
                  <Button
                    type="button"
                    onClick={() => removeRow(formEndorsementData.id_endorsement)}
                    className="btn btn-danger btn-md"
                  >
                    <DeleteForeverRoundedIcon />
                  </Button>
                )}
              </div>
            </div>

            {/* Tabla de Asegurados asociados */}
            <h5>Asegurados en tramite asociados</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Policy Number</th>
                  <th>Certificado</th>
                  <th>Subgrupo</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {associatedProcedures?.map((insured) => (
                  <tr key={insured.id}>
                    <td>{insured.policy_number}</td>
                    <td>{insured.certificate_number}</td>
                    <td>{insured.subgroup_number}</td>
                    <td>{insured.first_name} {insured.last_name_paternal} {insured.last_name_maternal}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={insured.associated}
                        onChange={() => handleCheckboxChange(insured.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Tabla de procedimientos no asociados */}
            <h5>Asegurados en tramite No Asociados</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Policy Number</th>
                  <th>Certificado</th>
                  <th>Subgrupo</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {unassociatedProcedures?.map((insured) => (
                  <tr key={insured.id}>
                    <td>{insured.policy_number}</td>
                    <td>{insured.certificate_number}</td>
                    <td>{insured.subgroup_number}</td>
                    <td>{insured.first_name} {insured.last_name_paternal} {insured.last_name_maternal}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={insured.associated}
                        onChange={() => handleCheckboxChange(insured.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Button onClick={handleUpdate}>Actualizar Asegurados</Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

};

export default TestComponent;
