import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../../../global';
import { setSelectedOrganization } from "../../../features/modalSlice/modalPolicySlice";

const SearchOrganization = ({ onSelect }) => {
    const dispatch = useDispatch();
    const selectedOrganization = useSelector((state) => state.modalPolicy.selectedOrganization);
    const organization = useSelector((state) => state.organizations.data);
    const [value, setValue] = useState(selectedOrganization);
    const [inputValue, setInputValue] = useState('');
    const [orgDatas, setOrgDatas] = useState([]);

    useEffect(() => {
        if (organization.length) {
            setOrgDatas(organization);
        } else {
            infoGlobal.fetchOrganizations()
        }
    }, [organization, dispatch]);


    useEffect(() => {
        // Reinicia el valor seleccionado cuando cambia selectedOrganization
        setValue(selectedOrganization);
    }, [selectedOrganization]);

    useEffect(() => {
        // Limpia la selección cuando el componente se vuelve a montar
        setValue(null);
        setInputValue('');
    }, []);

    const filteredOptions = orgDatas.map(org => ({
        id: org.id_organization,
        label: `${org.id_organization}-${org.name}`,
    }));

    return (
        <Autocomplete
            disablePortal
            id="autocomplete"
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                dispatch(setSelectedOrganization(newValue));
                onSelect(newValue?.id);  // Llamada a la función de callback
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Buscar Organization" />}
        />
    );
};

export default SearchOrganization;
