import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const insurerSlice = createSlice({
  name: 'insurer',
  initialState,
  reducers: {
    setInsurer: (state, action) => {
      state.data = action.payload;
    },
    addInsurer: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { setInsurer, addInsurer } = insurerSlice.actions;
export const insurerReducer = insurerSlice.reducer;

export default insurerSlice.reducer;
