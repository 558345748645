import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../../global';
import { getHolderByOrganization, getPoliciesByHolder } from '../../api/PolicyHolderService';
import { getCertificatesBySubgroup } from '../../api/CertificateService';
import { getSubgroupByPolicyId } from '../../api/SubgroupService';
import SearchOrganization from '../SearchAutoComplete/Organization/SearchOrganization';
import SubgroupExcelUpload from './SubgroupExcelUpload';

function ExcelSelect() {
    const dispatch = useDispatch();

    const { getRootProps, getInputProps, isDragActive } = useDropzone();
    const organization = useSelector((state) => state.organizations.data);
    const user = useSelector((state) => state.users.data);
    const [policyHolders, setPolicyHolders] = useState([]); // Estado para almacenar los contratantes
    const [policies, setPolicies] = useState([]); // Estado para almacenar las pólizas
    const [selectedHolder, setSelectedHolder] = useState(''); // Estado para el contratante seleccionado
    const [selectedPolicy, setSelectedPolicy] = useState(''); // Estado para la póliza seleccionada
    const [selectedSubgroup, setSelectedSubgroup] = useState(''); // Estado para el subgrupo seleccionado
    const [subgroups, setSubgroups] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [orgDatas, setOrgDatas] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedPolicyHolder, setSelectedPolicyHolder] = useState('');

    useEffect(() => {
        if (!organization.length) {
            infoGlobal.fetchOrganizations();
        } else {
            setOrgDatas(organization)
        }
        if (!user.length) {
            infoGlobal.fetchRole();
        }
    }, [organization, user, dispatch]);

    const handleOrganizationChange = async (selectedOrgId) => {
        try {
            setSelectedOrganization(selectedOrgId);
            console.log("ID DESDE ORG>JS PRUEBNA", selectedOrgId)
            const data = await getHolderByOrganization(selectedOrgId);
            setPolicyHolders(data.holders);
        } catch (error) {
            console.error(error)
        }
    };
    const handlePolicyHolderChange = async (event) => {
        try {
            const selectedPolicyHolder = event.target.value;
            console.log("selectedPolicyHolder", selectedPolicyHolder);
            setSelectedPolicyHolder(selectedPolicyHolder);
            if (!selectedPolicyHolder == '') {
                //obtener las polizas dado el id del policy holder
                const data = await getPoliciesByHolder(selectedPolicyHolder);
                console.log("Data de getPoliciesByHolder", data)
                setPolicies(data);
            } else {
            }
        } catch (error) {
            console.error('Error handling policyHolder change:', error);
        }
    };
    const handlePolicyChange = async (event) => {
        try {
            const selectedPolicy = event.target.value;
            console.log("selectedPolicy", selectedPolicy);
            setSelectedPolicy(selectedPolicy);
            if (!selectedPolicy == '') {
                const dataSubgroups = await getSubgroupByPolicyId(event.target.value);
                console.log("Data sub", dataSubgroups);
                setSubgroups(dataSubgroups);
            } else {
            }
        } catch (error) {
            console.error('Error fetching subgroups:', error);
        }
    };

    const handleSubgorupChange = async (event) => {
        try {
            const selectedSubgroup = event.target.value;
            setSelectedSubgroup(selectedSubgroup);

        } catch (error) {
            console.error('Error handling subgroup change:', error);
        }
    };
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <SearchOrganization onSelect={handleOrganizationChange} />
                </div>
                <div className="col-md-6">
                    <div className="form-floating mb-3">
                        <select
                            name="PolicyHolder_id"
                            value={selectedPolicyHolder}
                            onChange={handlePolicyHolderChange}
                            className="form-control"
                            id="PolicyHolder_id"
                        >
                            <option value="">Selecciona un Contratante</option>
                            {policyHolders.map((holder) => (
                                <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                                    {holder.id_policy_holder} - {holder.business_name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="PolicyHolder_id">Contratante</label>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating mb-3">
                        <select
                            name='policy_id'
                            value={selectedPolicy}
                            onChange={handlePolicyChange}
                            className="form-control"
                            id="policy_id"

                        >
                            <option value="">Selecciona una Póliza</option>
                            {policies.map(policy => (
                                <option key={policy.id_policy} value={policy.id_policy}>
                                    {policy.policy_number}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="policy_id">Póliza</label>
                    </div>

                </div>
                <div className="col-md-6">
                    <div className="form-floating mb-3">
                        <select
                            name="subgroup_id"
                            value={selectedSubgroup}
                            onChange={handleSubgorupChange}
                            className="form-control"
                            id="subgroup_id"
                        >
                            <option value="">Selecciona un Subgrupo</option>
                            {subgroups.map((subgroup) => (
                                <option key={subgroup.id_subgroup} value={subgroup.id_subgroup}>
                                    {subgroup.subgroup_number} - {subgroup.subgroup_name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="subgroup_id">Subgrupo Numero-Nombre</label>

                    </div>
                </div>
            </div>
            {selectedSubgroup && (
                <SubgroupExcelUpload subgroup_id={selectedSubgroup} />
            )}
        </div>
    )

}
export default ExcelSelect;
