import React, { useState } from 'react';
import { postExecutive } from '../api/executiveService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function ExecutiveForm({ updateExecutiveList }) {
  const [botonAdd, setBotonAdd] = useState(false);
  const [formData, setFormData] = useState({ photo_path: '', name: '', department: '', position: '', email: '', whatsapp: '', phone: '' });

  const uniqueId = () => {
    return Math.floor(Math.random() * Date.now());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleImageChange = (event) => {
    const randomId = uniqueId();

    setFormData({ ...formData, photo_path: event.target.files[0], com_id_executive: "Executive_" + randomId });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //Generar el com_id_Executive
    try {
      await postExecutive(formData)
      setBotonAdd(false); // Después de enviar el formulario, cambia el estado de botonAdd a false
      setFormData({ photo_path: '', name: '', department: '', position: '', email: '', whatsapp: '', phone: '' }); // Limpia los datos del formulario
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El ejecutivo se ha añadido correctamente.',
      });
      uniqueId();
      updateExecutiveList()
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        html: `Ha ocurrido un error al eliminar El certificado.<br/><strong>${error.response.data.message}</strong>`,
      });
    }

  };


  if (!botonAdd) {
    return (
      <div>
        {!botonAdd && (
          <div>
            <h2> Ejecutivos</h2>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success" onClick={() => setBotonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <h2>Ejecutivos</h2>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="mb-3">
            <label htmlFor="photo" className="form-label">Imagen</label>
            <input
              type="file"
              id="photo"
              name="photo"
              accept="image/*"
              onChange={handleImageChange}
              className="form-control"
            />
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
              id="floatingName"
              placeholder=" "
            />
            <label htmlFor="floatingName">Nombre</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="department"
              value={formData.department}
              onChange={handleChange}
              className="form-control"
              id="floatingDepartment"
              placeholder=" "
            />
            <label htmlFor="floatingDepartment">Departamento</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating mb-3">
            <input
              type="text"
              name="position"
              value={formData.position}
              onChange={handleChange}
              className="form-control"
              id="floatingPosition"
              placeholder=" "
            />
            <label htmlFor="floatingPosition">Posición</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              id="floatingEmail"
              placeholder=" "
            />
            <label htmlFor="floatingEmail">Email</label>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="form-floating mb-3">
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="form-control"
              id="floatingPhone"
              placeholder=" "
            />
            <label htmlFor="floatingPhone">Teléfono</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="whatsapp"
              value={formData.whatsapp}
              onChange={handleChange}
              className="form-control"
              id="floatingWhatsapp"
              placeholder=" "
            />
            <label htmlFor="floatingWhatsapp">Teléfono de Whatsapp</label>
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-primary me-3">Submit</button>
      <button className="btn btn-danger" onClick={() => setBotonAdd(false)}>Cancelar</button>
    </form>
  );

}


export default ExecutiveForm;
