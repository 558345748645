import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';

const SearchPolicy = ({ data, onSelect, isDisabled, defaultValue }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        // Si se pasa un valor predeterminado, lo establece al montar
        if (defaultValue) {
            setValue({
                id: defaultValue.id_policy,
                label: defaultValue.policy_number,
            });
        }
    }, [defaultValue]);

    const filteredOptions = data.map(policy => ({
        id: policy.id_policy,
        label: `${policy.policy_number}`,
    }));

    return (
        <Autocomplete
            disablePortal
            id="autocomplete"
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            value={value}
            disabled={isDisabled}  // Usa la prop `isDisabled`
            onChange={(event, newValue) => {
                setValue(newValue);
                onSelect(newValue?.id);  // Llamada a la función de callback
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Buscar Póliza" />}
        />
    );
};

export default SearchPolicy;
