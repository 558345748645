import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  selectedOrganization: null, // Añade esta propiedad al estado inicial si aún no existe
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.data = action.payload;
    },
    addOrganization: (state, action) => {
      state.data.push(action.payload);
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    clearSelectedOrganization: (state) => {
      state.selectedOrganization = null;
    },
  },
});

export const { setOrganizations, addOrganization, setSelectedOrganization, clearSelectedOrganization } = organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;

export default organizationSlice.reducer;
