import React from 'react';
import { Modal, Table, Button, Spinner, Alert } from 'react-bootstrap'; import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getLogs } from '../../../api/LogsServices';


const MySwal = withReactContent(Swal);

// Función para formatear las fechas
const formatDate = (date) => {
    return date ? new Date(date).toLocaleString() : 'NA';
};

const ModalLogs = ({ show, onHide, data }) => {


    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Historial de Correos de {data.complete_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Enviado a</th>
                            <th>Tipo</th>
                            <th>Fecha de envio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.emailLogs.map((log, index) => (
                            <tr key={index}>
                                <td>{log.email ? log.email : "Error"}</td>
                                <td>{log.subject ? log.subject : "Error"}</td>
                                <td>{log.created_at ? formatDate(log.created_at) : "Error"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalLogs;