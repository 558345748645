import React, { useState, useEffect } from 'react';
import { Modal, Button, Accordion, Form } from 'react-bootstrap';
import { sendCertificate, sendToAdminPolicy } from '../../api/CertificateService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { attachCertificate } from '../../api/InsuredService';



const ModalAddInsured = ({ showModal, handleClose, selectedId, fetchCertificates }) => {
  const MySwal = withReactContent(Swal);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name_paternal: '',
    last_name_maternal: '',
    work_email: '',
    rfc: '',
    curp: '',
    phone_office: '',
    phone_personal: '',
    rol: ""
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      await attachCertificate(selectedId, formData);
      if (formData.email || formData.work_email) {
        Swal.fire({
          title: '¡Éxito!',
          text: 'El certificado se ha enviado correctamente. ¿Quieres enviarlo al administrador de la póliza?',
          showCancelButton: true,
          confirmButtonText: 'Sí, enviar al administrador de la póliza',
          cancelButtonText: 'No, cerrar',
          icon: 'success',
        }).then((result) => {
          if (result.isConfirmed) {
            sendAdminCertificate(selectedId);
          } else {
            handleClose();
          }
        });
      } else {
        Swal.fire({
          title: '¡Éxito!',
          text: 'El certificado se ha asociado correctamente.',
          showCancelButton: true,
          cancelButtonText: 'Cerrar',
          icon: 'success',
        })
      }

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al asociar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };
  const sendAdminCertificate = async (id) => {
    try {
      await sendToAdminPolicy(id);
      Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El certificado se ha enviado correctamente. ¿Quieres enviar a otra persona?',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar a otra persona',
        cancelButtonText: 'No, cerrar',
      }).then((result) => {
        if (result.isConfirmed) {
          sendAnotherEmail(id); // Vuelve a preguntar para enviar a otra persona
        } else {
          Swal.close(); // Cierra el Swal si no se desea enviar a otra persona
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception|| 'Error desconocido'}</pre>`,
      });
    }
  };
  const sendAnotherEmail = async (id) => {
    try {
      // Solicitar el correo electrónico y enviar el certificado
      Swal.fire({
        icon: 'info',
        title: 'Enviar a otra persona',
        text: 'Introduce el email de la nueva persona',
        input: 'email',
        inputLabel: 'Correo electrónico',
        inputPlaceholder: 'Correo electrónico',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Enviar',
        inputValidator: (value) => {
          if (!value) {
            return 'El correo electrónico es obligatorio';
          }
          return null;
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newEmail = result.value;
          if (newEmail) {
            try {
              // Enviar el certificado al nuevo correo
              await sendToAdminPolicy(id, { email: newEmail });
              Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El certificado se ha enviado correctamente a la nueva persona.',
              }).then(() => {
                sendAnotherEmail(id); // Vuelve a preguntar para enviar a otra persona
              });
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al enviar el certificado.',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
              });
            }
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };

  return (
    <Modal dialogClassName="modal-xl" show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Asegurado y asociarlo al certificado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <h5>Datos Requeridos</h5>
          <div className="row">
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  id="last_name_paternal"
                  name="last_name_paternal"
                  value={formData.last_name_paternal}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Apellido Paterno"
                  required
                />
                <label htmlFor="last_name_paternal" className="form-label">
                  Apellido Paterno
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  id="last_name_maternal"
                  name="last_name_maternal"
                  value={formData.last_name_maternal}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Apellido Materno"
                  required
                />
                <label htmlFor="last_name_maternal" className="form-label">
                  Apellido Materno
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Primer nombre"
                  required
                />
                <label htmlFor="first_name" className="form-label">
                  Primer nombre
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <select
                  name="relationship"
                  value={formData.relationship}
                  onChange={handleChange}
                  className="form-select"
                  placeholder="Relación"
                  required
                >
                  <option value='holder'>Titular</option>
                  <option value='spouse'>Conyuge</option>
                  <option value='dependent'>Dependiente</option>
                </select>
                <label htmlFor="relationship" className="form-label">
                  Relación
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="date"
                  id="date_birth"
                  name="date_birth"
                  value={formData.date_birth}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Fecha de Nacimiento"
                />
                <label htmlFor="date_birth" className="form-label">
                  Fecha de Nacimiento
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="form-select"
                  placeholder="Género"
                  required
                >
                  <option value=''>Género</option>
                  <option value='M'>Masculino</option>
                  <option value='F'>Femenino</option>
                </select>
                <label htmlFor="gender" className="form-label">
                  Género
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="date"
                  id="current_antiquity"
                  name="current_antiquity"
                  value={formData.current_antiquity}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Antigüedad Actual"
                />
                <label htmlFor="current_antiquity" className="form-label">
                  Antigüedad Actual
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-2">
                <input
                  type="date"
                  id="total_antiquity"
                  name="total_antiquity"
                  value={formData.total_antiquity}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Antigüedad en otras aseguradoras"
                />
                <label htmlFor="total_antiquity" className="form-label">
                  Antigüedad en otras aseguradoras
                </label>
              </div>
            </div>
          </div>
          <Accordion className='mb-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Datos Extras</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        id="work_email"
                        name="work_email"
                        value={formData.work_email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Email del Trabajo"
                      />
                      <label htmlFor="work_email" className="form-label">
                        Email del Trabajo
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Email"
                      />
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="rfc"
                        name="rfc"
                        value={formData.rfc}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="RFC"
                      />
                      <label htmlFor="rfc" className="form-label">
                        RFC
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="curp"
                        name="curp"
                        value={formData.curp}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="CURP"
                      />
                      <label htmlFor="curp" className="form-label">
                        CURP
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="phone_office"
                        name="phone_office"
                        value={formData.phone_office}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Teléfono de Oficina"
                      />
                      <label htmlFor="phone_office" className="form-label">
                        Teléfono de Oficina
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        id="phone_personal"
                        name="phone_personal"
                        value={formData.phone_personal}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Teléfono Personal"
                      />
                      <label htmlFor="phone_personal" className="form-label">
                        Teléfono Personal
                      </label>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Button variant="primary" type="submit">
            Crear
          </Button>
        </Form>
      </Modal.Body>

    </Modal>

  );
};

export default ModalAddInsured;