import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { getFinalizaedProcedures, getOldProcedures } from '../../api/ProceduresService';
import { format } from 'date-fns';

const DashboardProcedures = ({ type }) => {
    const [pendings, setPendings] = useState([]);
    const [finalized, setFinalized] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const pendingProcedures = await getOldProcedures();
            const finalizedProcedures = await getFinalizaedProcedures();
            console.log(pendingProcedures)
            setPendings(pendingProcedures);
            setFinalized(finalizedProcedures);
        } catch (error) {
            console.error('Error fetching Procedures:', error);
        }
    };

    const calculateDaysElapsed = (date) => {
        if (!date) return 'NA';
        const requestDate = new Date(date);
        const today = new Date();
        const timeDiff = today - requestDate;
        return `${Math.floor(timeDiff / (1000 * 60 * 60 * 24))} Días`;
    };

    const formatDate = (dateString) => {
        try {
            return format(new Date(dateString), 'yyyy-MM-dd');
        } catch (error) {
            console.error('Error formateando la fecha:', error);
            return '';
        }
    };

    const differenceInDays = (dateString) => {
        const today = new Date();
        const updateDate = new Date(dateString);
        const diffTime = Math.abs(today - updateDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    const getColorByDaysDifference = (dateString) => {
        const daysDifference = differenceInDays(dateString);

        if (daysDifference <= 5) {
            return 'table-success'; // Verde para actualizado recientemente
        } else if (daysDifference <= 10) {
            return 'table-warning'; // Amarillo para actualizado hace poco
        } else {
            return 'table-danger'; // Rojo para actualizado hace mucho
        }
    };

    const filteredPendings = pendings.filter(procedure => procedure.policy_branch === type);
    const filteredFinalized = finalized.filter(procedure => procedure.policy_branch === type);

    return (
        <Container fluid>
            <Row>
                <Col md={11} className="d-flex flex-column">
                    {/* Pendientes */}
                    <Row>
                        <h5>Trámites En Proceso</h5>
                        <Col className="mb-3" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Organización</th>
                                        <th>Aseguradora</th>
                                        <th>Tipo</th>
                                        <th>Rama</th>
                                        <th>Trámite</th>
                                        <th>Detalles</th>
                                        <th>Estatus</th>
                                        <th>Folio aseguradora</th>
                                        <th>Días Transcurridos</th>
                                        <th>Actualizado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPendings.map(procedure => (
                                        <tr key={procedure.id_procedure} className={getColorByDaysDifference(procedure.updated_at)}>
                                            <td>{procedure.organization.name || 'N/A'}</td>
                                            <td>{procedure.insurer.commercial_name || 'N/A'}</td>
                                            <td>{procedure.policy.policy_type || 'N/A'}</td>
                                            <td>{procedure.policy_branch || 'N/A'}</td>
                                            <td>{procedure.procedure_type || 'N/A'}</td>
                                            <td>{procedure.details || 'N/A'}</td>
                                            <td>{procedure.status || 'N/A'}</td>
                                            <td>{procedure.insurer_request_date || 'N/A'}</td>
                                            <td>{calculateDaysElapsed(procedure.insurer_request_date)}</td>
                                            <td>{formatDate(procedure.updated_at)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {/* Finalizados */}
                    <Row>
                        <h5>Trámites Finalizados en los Últimos 7 Días</h5>
                        <Col style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Organización</th>
                                        <th>Aseguradora</th>
                                        <th>Tipo</th>
                                        <th>Trámite</th>
                                        <th>Detalles</th>
                                        <th>Estatus</th>
                                        <th>Folio aseguradora</th>
                                        <th>Actualizado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredFinalized.map(procedure => (
                                        <tr key={procedure.id_procedure} className={getColorByDaysDifference(procedure.updated_at)}>
                                            <td>{procedure.organization.name || 'N/A'}</td>
                                            <td>{procedure.insurer.commercial_name || 'N/A'}</td>
                                            <td>{procedure.policy.policy_type || 'N/A'}</td>
                                            <td>{procedure.procedure_type || 'N/A'}</td>
                                            <td>{procedure.details || 'N/A'}</td>
                                            <td>{procedure.status || 'N/A'}</td>
                                            <td>{procedure.insurer_request_date || 'N/A'}</td>
                                            <td>{formatDate(procedure.updated_at)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardProcedures;
