import React, { useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, lighten, IconButton } from '@mui/material';
import MoreSharpIcon from '@mui/icons-material/MoreSharp';
import { useDispatch } from 'react-redux';
import { getProcedures } from '../../../api/ProceduresService.js';
import { useNavigate } from 'react-router-dom';
import ModalActionsProcedures from './ModalActionsProcedures.js';
import ModalChangesProcedures from './ModalChangesProcedures.js';

const calculateDaysElapsed = (date) => {
  if (!date) return 'NA'; // Retorna 'NA' si no hzay fecha
  const requestDate = new Date(date);
  const today = new Date();
  const timeDiff = today - requestDate;
  const daysElapsed = `${Math.floor(timeDiff / (1000 * 60 * 60 * 24))} Dias`;
  return daysElapsed;
};

const transformProcedureType = (type) => {
  const procedureTypeMap = {
    'RA': 'Reconocimiento de Antigüedad',
    'AA': 'Alta de Asegurado',
    'BA': 'Baja de Asegurado',
    'CD': 'Corrección en Datos',
    'EI': 'Emisión Inicial'
  };

  return procedureTypeMap[type] || type;
};

const MainTable = () => {
  const navigate = useNavigate(); // Inicializa el hook de navegación

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Estado para controlar la fila seleccionada
  const [modalOpen, setModalOpen] = useState(false);
  const [modalChanges, setModalChanges] = useState(false);
  const [policyNumber, setPolicyNumber] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getProcedures();
      //setPolicyNumber(response.policy_number);
      setData(response.procedures);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'details',
        header: 'Detalles',
        columns: [
          { accessorKey: 'organization.name', header: 'Organizacion', size: 50 },
          { accessorKey: 'insurer.commercial_name', header: 'Aseguradora', size: 50 },
          { accessorKey: 'policy.policy_type', header: 'Tipo', size: 50 },
          { accessorKey: 'procedure_type', header: 'Trámite', size: 30, Cell: ({ cell }) => transformProcedureType(cell.getValue()) },
          { accessorKey: 'details', header: 'Detalles', size: 50, Cell: ({ cell }) => { const value = cell.getValue(); return value ? value : 'NA'; } },
          { accessorKey: 'status', header: 'Estatus', size: 50 },
          { accessorKey: 'insurer_folio', header: 'Folio Aseguradora', size: 50 },
          { accessorKey: 'insurer_request_date', header: 'Días Transcurridos', size: 50, Cell: ({ cell }) => calculateDaysElapsed(cell.getValue()) },
          { accessorKey: 'internal_folio', header: 'Folio Interno', size: 50, show: false },
          {
            accessorFn: (row) => `${row.insured?.first_name || ''} ${row.insured?.last_name_paternal || ''} ${row.insured?.last_name_maternal || ''}`,
            header: 'Asegurado',
            size: 50,
            show: false,
          },
        ],
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    console.log('row', row);
    setSelectedRow(row); // Establece la fila seleccionada

    // Abre el modal
   // setModalOpen(true);

    // Redirige a la página deseada
    navigate(`/procedureDetails/${row.id_procedure}`, { state: { procedureData: row } });
  };

  const handleSeeChanges = () => {
    const selectedRows = table.getSelectedRowModel().flatRows;
    if (selectedRows.length === 0) {
      alert("No hay filas seleccionadas.");
      return;
    }

    selectedRows.forEach((row) => {
      console.log('row', row);
      console.log('rowOg', row.original);
      setSelectedRow(row.original); // Asegúrate de que sea row.original
      setModalChanges(true); // Abre el modal
    });
  };

  const table = useMaterialReactTable({
    columns: columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton color="primary" onClick={() => handleRowClick(row.original)}>
          <MoreSharpIcon fontSize="large" />
        </IconButton>
      </Box>
    ),
    muiFilterTextFieldProps: ({ column }) => ({
      label: `${column.columnDef.header}`,
    }),
    enableRowSelection: true,
    enableMultiRowSelection: false,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      placeholder: 'Busqueda Global',
      size: 'medium',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderTopToolbar: ({ table }) => {
      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          const rowData = row.original; // Obtiene todos los datos de la fila
          const message = Object.entries(rowData)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
          alert('Desactivando:\n' + message);
        });
      };
      return (
        <div>
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <Button
                  color="error"
                  onClick={handleDeactivate}
                  variant="contained"
                >
                  Desactivar
                </Button>
                <Button
                  color="error"
                  onClick={handleSeeChanges}
                  variant="contained"
                >
                  Cambios
                </Button>
                <Button
                  color="primary"
                  onClick={fetchData} // Añadido botón para actualizar los datos
                  variant="contained"
                >
                  Actualizar
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      );
    },
  });

  return (
    <>
      <MaterialReactTable
        table={table}
        columns={columns[0].columns.map((col) => ({
          ...col,
        }))}
      />

      {modalOpen && selectedRow && (
        <ModalActionsProcedures
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          data={selectedRow}
        />
      )}
      {modalChanges && selectedRow && (
        <ModalChangesProcedures
          show={modalChanges}
          onHide={() => setModalChanges(false)}
          data={selectedRow}
        />
      )}
    </>
  );
};

const MainTableProcedures = ({ }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MainTable />
  </LocalizationProvider>
);

export default MainTableProcedures;
