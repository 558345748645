import React, { useState } from 'react';
import { postOrganization } from '../../api/OrganizationService';
import { useDispatch } from 'react-redux';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { Modal } from 'react-bootstrap';
import { addOrganization } from '../../features/organization/organizationSlice';

const MySwal = withReactContent(Swal);

function OrganizationForm({ updateList, buttonAddOrganization, onClose }) {
  const [buttonAdd, setButtonAdd] = useState(false);
  const [formData, setFormData] = useState({ name: '', phone_number: '' });
  const dispatch = useDispatch();


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await postOrganization(formData);
      dispatch(addOrganization(response.organization)); 
      if (updateList) {
        updateList()
      }
      setButtonAdd(false);
      setFormData({ name: '', phone_number: '' });
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'La organización se ha añadido correctamente.',
      });
      if (onClose) {
        onClose();
      }
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
        text: 'Ha ocurrido un error al añadir la organización.',
      });
      console.error('Error adding organization:', error);
    }
  };

  if (buttonAddOrganization) {
    return (
      <Modal dialogClassName="modal-lg" show={buttonAddOrganization} onHide={() => onClose()}>
        <form onSubmit={handleSubmit} className="container mt-4">
          <Modal.Header closeButton>
            <Modal.Title>Organizacion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" placeholder="Nombre de la organizacion" required />
            </div>

            <div className="mb-3">
              <input type="number" name="phone_number" value={formData.phone_number} onChange={handleChange} className="form-control" placeholder="Telefono de la organizacion" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-danger" onClick={() => onClose()}>Cancelar</button>
            <button type="submit" className="btn btn-primary me-3">Guardar</button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
  if (!buttonAdd) {
    return (
      <div>
        <h2> Organizacion</h2>
        {!buttonAdd && (
          <div>
            <div className="mb-4">
              <button className="btn btn-success w-100" onClick={() => setButtonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <div className="mb-3">
        <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" placeholder="Nombre de la Organizacion" />
      </div>

      <div className="mb-3">
        <input type="number" name="phone_number" value={formData.phone_number} onChange={handleChange} className="form-control" placeholder="Telefono" />
      </div>

      <button type="submit" className="btn btn-primary">Submit</button>
      <button className="btn btn-danger" onClick={() => setButtonAdd(false)}>Cancelar</button>

    </form>
  );
}


export default OrganizationForm;
