import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link, Routes, Route, useParams, useLocation } from 'react-router-dom';
import InsurersList from './components/InsurerList';
import ExecutivesList from './components/ExecutiveList';
import PolicyList from './components/PolicyList';
import CarouselList from "./components/CarruselList";
import UserForm from './components/UserForm';
import UserList from './components/UserList';
import 'bootstrap/dist/css/bootstrap.min.css';
import { grmSeguWalletLogo } from "./global";
import PolicyHolderList from './components/PolicyHolderList';
import SubgroupList from './components/SubgroupList';
import CertificateList from './components/CertificateList';
import ContactList from './components/ContactList';
import InsuredList from './components/InsuredList';
import PolicyDetailsList from './components/PolicyDetailsList';
import ChangeRole from './components/ChangeRole';
import Dashboard from './pages/dashboard/Dashboard';
import { initializeApp } from './global';
import PoolList from './components/Admin/Pools/PoolList';
import ExampleWithLocalizationProvider from './components/Tables/MainTable.js';
import SelectedOrganizations from './pages/Policies/Policies.js';
import CertificatesForm from './components/CertificatesForm.js';
import MainTableCertificates from './components/Tables/TableCertificates/TableListCertificates.js';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainTableInsured from './components/Tables/TableInsured/TableListInsured.js';
import MainTableSubgroup from './components/Tables/TableSubgroups/TableListSubgroups.js';
import Procedures from './pages/Procedures/Procedures.js';
import ExcelSelect from './components/Excel/ExcelSelect.js';
import OrganizationList from './components/Organization/OrganizationList.js';
import { FileUpload } from '@mui/icons-material';
import UploadFileComponent from './components/Admin/UploadFile.js';
import RegisterUserForm from './components/Admin/Users/RegisterUserForm.js';
import Policies from './pages/Policies/Policies.js';
import PolicyDetail from './components/Policies/PolicyDetails.js';
import PolicyDetails from './components/Policies/PolicyDetails.js';
import TestComponent from './features/testpage.js';
import ModalEditInsured from './components/Tables/TableInsured/ModalEditInsured.js';
import ModalEditPolicie from './components/Policies/ModalEditPolicies.js';
import TableInsuredProcedure from './components/Procedures/TableInsuredProcedure.js';
import ProcedureDetails from './components/Procedures/ProcedureDetails.js';

function DashboardRoutes() {
  useEffect(() => {
    initializeApp();
  }, []);

  return (
    <Router>
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              <img
                src={grmSeguWalletLogo}
                style={{ width: '40px', marginRight: '10px' }}
                alt="SeguWallet Logo"
              />
              <span className="text-danger fw-bold">GRM Admin</span>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-start" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownAdmins" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Operacion
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAdmins">
                    <li><Link className="dropdown-item" to="/registerData">Polizas</Link></li>
                    <li><Link className="dropdown-item" to="/procedures">Tramites</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownOperaciones" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Operaciones
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownOperaciones">
                    <li ><Link className="dropdown-item" to="/polizas">Polizas</Link></li>
                    <li ><Link className="dropdown-item" to="/subgrupos">Subgrupos</Link></li>
                    <li ><Link className="dropdown-item" to="/certificados">Certificados</Link></li>
                    <li ><Link className="dropdown-item" to="/asegurados">Asegurados</Link></li>
                    <li ><Link className="dropdown-item" to="/detalles-poliza">Detalles Poliza</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownCatalogos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Catálogos
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownCatalogos">
                    <li ><Link className="dropdown-item" to="/organizacion">Organizacion</Link></li>
                    <li ><Link className="dropdown-item" to="/contratante">Contratante</Link></li>
                    <li ><Link className="dropdown-item" to="/contactos">Contactos</Link></li>
                    <li ><Link className="dropdown-item" to="/pools">Pools</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownAdmins" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Administrador
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAdmins">
                    <li><Link className="dropdown-item" to="/aseguradoras">Aseguradoras</Link></li>
                    <li><Link className="dropdown-item" to="/ejecutivos">Ejecutivos</Link></li>
                    <li><Link className="dropdown-item" to="/carrusel">Carrusel</Link></li>
                    <li ><Link className="dropdown-item" to="/change-role">Cambiar Role</Link></li>
                    <li ><Link className="dropdown-item" to="/registerUserForm">Registro de Usuario</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownAdmins" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Pruebas
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAdmins">
                    <li><Link className="dropdown-item" to="/test">Pruebas</Link></li>
                  </ul>
                </li>

              </ul>
            </div>
          </div>
        </nav>


        <Routes>
          <Route path="/" element={<DashboardPage />} />

          <Route path="/aseguradoras" element={<InsurersPage />} />
          <Route path="/ejecutivos" element={<ExecutivesPage />} />
          <Route path="/usuarios" element={<UsersPage />} />
          <Route path="/polizas" element={<PoliciesPage />} />
          <Route path="/carrusel" element={<CarruselPage />} />
          <Route path="/organizacion" element={<OrganizationPage />} />
          <Route path="/contratante" element={<PolicyHolderPage />} />
          <Route path="/subgrupos" element={<SubgroupPage />} />
          <Route path="/certificados" element={<CertificatePage />} />
          <Route path="/contactos" element={<ContactPage />} />
          <Route path="/asegurados" element={<InsuredPage />} />
          <Route path="/detalles-poliza" element={<PoliciesDetails />} />
          <Route path="/change-role" element={<ChangeRolePage />} />
          <Route path="/pools" element={<PoolsPage />} />
          <Route path="/dataHubOrganization" element={<DataHubOrganization />} />
          <Route path="/searchTest" element={<SearchOrganizationPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/registerData" element={<SearchOrganizationPage />} />
          //?  Modal de poliza ?//
          //?---------------------------------------------------------------?//
          <Route path="/insuredFetch/:id" element={<InsuredFetchPage />} />
          <Route path="/insuredCreate" element={<SearchOrganizationPage />} />
          <Route path="/subgroupsFetch/:id" element={<SubgroupFetchPage />} />
          <Route path="/subgroupCreate" element={<SearchOrganizationPage />} />
          <Route path="/certificatesFetch/:id" element={<CertificateFetchPage />} />
          <Route path="/certificateCreate" element={<CertificateCreate />} />
          <Route path="/policyDetails/:id" element={<PolicyDetailsPage />} />
          <Route path="/procedureDetails/:id" element={<ProcedureDetailsPage />} />
          //?---------------------------------------------------------------?//
          //?Tramites
          <Route path="/procedures" element={<ProceduresPage />} />
          //?Usuarios
          <Route path="/registerUserForm" element={<RegisterPage />} />



        </Routes>
      </div>
    </Router>
  );
}
function PolicyDetailsPage() {
  const { id } = useParams();
  const location = useLocation();

  const { policyData } = location.state || {};
  console.log("policyData desde el dashbaordRoutes", policyData)
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        variant="outlined"
        color="primary"
        sx={{ mb: 2 }}
      >
        Atrás
      </Button>
      <PolicyDetails policyData={policyData} id={id} />
    </div>
  );
}
function ProcedureDetailsPage() {
  const { id } = useParams();
  const location = useLocation();

  const { procedureData } = location.state || {};
  console.log("procedureData desde el dashbaordRoutes", procedureData)
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        variant="outlined"
        color="primary"
        sx={{ mb: 2 }}
      >
        Atrás
      </Button>
      <ProcedureDetails data={procedureData} id={id} />
    </div>
  );
}
function CertificateFetchPage() {
  const { id } = useParams();

  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        variant="outlined"
        color="primary"
        sx={{ mb: 2 }} // Añade margen inferior
      >
        Atrás
      </Button>
      <MainTableCertificates policy_id={id} />
    </div>
  );
}
function InsuredFetchPage() {
  const { id } = useParams();

  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        variant="outlined"
        color="primary"
        sx={{ mb: 2 }} // Añade margen inferior
      >
        Atrás
      </Button>
      <MainTableInsured policy_id={id} />
    </div>
  );
}


function SubgroupFetchPage() {
  const { id } = useParams();

  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        variant="outlined"
        color="primary"
        sx={{ mb: 2 }} // Añade margen inferior
      >
        Atrás
      </Button>
      <MainTableSubgroup policy_id={id} />
    </div>
  );
}
function CertificateCreate() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <CertificatesForm buttonAddOrganization={true} />
    </div>
  );
}
function RegisterPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <RegisterUserForm />
    </div>
  );
}

function CarruselPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <h2>Imagenes del Carrusel</h2>
      <CarouselList />
    </div>
  );
}
function TestPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <h2>Pagina de pruebas</h2>
      {/* <UploadFileComponent />
      <ModalEditPolicie /> */}
      <TestComponent/> 
      </div>
  );
}
function ProceduresPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Procedures />
    </div>
  );
}
function SearchOrganizationPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <h2>Polizas por Organizacion</h2>
      <Policies />
    </div>
  );
}
function PoolsPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <PoolList />
    </div>
  );
}
function DataHubOrganization() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <ExampleWithLocalizationProvider />
    </div>
  );
}
function DashboardPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <Dashboard />
    </div>
  );
}
function ChangeRolePage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <ChangeRole />
    </div>
  );
}
function InsurersPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">

      <InsurersList />
    </div>
  );
}

function ExecutivesPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <ExecutivesList />
    </div>
  );
}
function UsersPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <UserForm />
      <UserList />
    </div>
  );
}
function PoliciesPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <PolicyList />
    </div>
  );
}
function PoliciesDetails() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <PolicyDetailsList />
    </div>
  );
}
function OrganizationPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <OrganizationList />
    </div>
  );
}
function PolicyHolderPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <PolicyHolderList />
    </div>
  );
}
function SubgroupPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <SubgroupList />
    </div>
  );
}
function CertificatePage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <CertificateList />
    </div>
  );
}
function ContactPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <h2> Contactos</h2>
      <ContactList />
    </div>
  );
}
function InsuredPage() {
  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <InsuredList />
    </div>
  );
}
export default DashboardRoutes;
