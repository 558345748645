import React, { useState, useEffect } from 'react';
import { getCarousel, deleteCarousel, changeActivate } from "../api/CarouselService";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import CarouselForm from './CarruselForm';


const MySwal = withReactContent(Swal);

function CarouselList() {
    const [carousel, setcarousel] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [updatedData, setUpdatedData] = useState({});

    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Quieres Borrar esta imagen del carrusel?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire('Eliminado!', '', 'success');
                // Filtrar la lista de elementos del carrusel para eliminar solo el elemento con el ID correspondiente
                setcarousel(prevCarousel => prevCarousel.filter(item => item.id !== id));
                // Llamar a la función para eliminar el elemento del backend
                await deleteCarousel(id);
            }
        });
    };

    const handleUpdateCarouselList = async () => {
        await fetchCarousels();
    };
    const fetchCarousels = async () => {
        try {
            const data = await getCarousel();
            setcarousel(data.carouselImages); // verificar
        } catch (error) {
            console.error('Error fetching executives:', error);
        }
    };
    useEffect(() => {
        fetchCarousels();
    }, []);
    const handleFieldChange = (fieldName, value) => {
        setUpdatedData({ ...updatedData, [fieldName]: value });
    };
    const handleChangeDisplay = async (id) => {
        try {
            const response = await changeActivate(id);
            handleUpdateCarouselList()
            console.log(response);
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div>
            <CarouselForm updateCarouselList={handleUpdateCarouselList} />
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Titulo</th>
                        <th scope="col">Descripcion</th>
                        <th scope="col">Enlace</th>
                        <th scope="col">Imagen</th>
                        <th scope="col">Acciones</th>

                    </tr>
                </thead>
                <tbody>
                    {carousel.map((carousel) => (
                        <tr key={carousel.id}>

                            <td>{editingId === carousel.id ? <input type="text" defaultValue={carousel.title} onChange={(e) => handleFieldChange('Titulo', e.target.value)} /> : carousel.title}</td>

                            <td>{editingId === carousel.id ? <input type="text" defaultValue={carousel.description} onChange={(e) => handleFieldChange('Descripcion', e.target.value)} /> : carousel.description}</td>
                            <td>{editingId === carousel.id ? <input type="text" defaultValue={carousel.link} onChange={(e) => handleFieldChange('Link', e.target.value)} /> : carousel.link}</td>
                            <td><img src={carousel.image_link} alt={carousel.image_link} style={{ width: '80px', marginRight: '10px' }} /></td>
                            <td>
                                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(carousel.id)} >Borrar</button>
                            </td>
                            <td>
                                <button
                                    className="btn btn-info btn-sm"
                                    onClick={() => handleChangeDisplay(carousel.id)}
                                >
                                    {carousel.active ? 'Desactivar' : 'Activar'}
                                </button>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CarouselList
