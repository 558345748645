// pdfHandlers.js
import MySwal from 'sweetalert2'; // Asegúrate de que estás importando MySwal correctamente
import { deletePDF } from '../../api/PdfService'; // Ajusta la importación según tu estructura de archivos
import { useDropzone } from 'react-dropzone';
import React, { useState, useEffect } from 'react';

export const handleDeletePDF = async (pdfId) => {
  try {
    await deletePDF(pdfId);
    console.log(`Eliminar PDF ${pdfId}`);
  } catch (error) {
    console.error('Error deleting PDF:', error);
    MySwal.fire({
      icon: 'error',
      title: '¡Error!',
      text: 'Ha ocurrido un error al eliminar el PDF.',
    });
  }
};

export const openPdf = (pdfUrl) => {
  const newWindow = window.open(pdfUrl);
  if (newWindow) {
    newWindow.onload = () => {
      newWindow.fetch(pdfUrl, {
      });
    };
  }
};
export const RenderDropzone = ({ onFilesChange, category = 'NA', fileName = '', files = []}) => {
  const [fileList, setFileList] = useState(files);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const updatedFiles = [...fileList, ...acceptedFiles];
      setFileList(updatedFiles);
      onFilesChange(updatedFiles, category);
    },
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const handleRemoveAllFiles = () => {
    setFileList([]);
    onFilesChange([], category);
  };



  const dropzoneStyle = {
    border: '2px inset  #0031f8', // Color de borde predeterminado (secondary)
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '5px',
    position: 'relative',
  };

  const activeDropzoneStyle = {
    ...dropzoneStyle,
    borderColor: '#007bff' // Color de borde cuando está activo (primary)
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    background: '#dc3545', // Color de fondo del botón de eliminación
    color: '#fff',
    border: 'none',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: fileList.length > 0 ? 'flex' : 'none', // Mostrar solo si hay archivos
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    zIndex: 10 // Asegura que el botón esté sobre el área de arrastre
  };

  return (
    <div style={{ position: 'relative', marginBottom: '10px' }}>
      <button
        type="button"
        style={closeButtonStyle}
        onClick={handleRemoveAllFiles}
        title="Eliminar todos los archivos"
      >
        &times;
      </button>
      <div style={{ textAlign: 'center', marginTop: '5px' }}>{fileName}</div>
      <div>
        <div
          {...getRootProps()}
          style={isDragActive ? activeDropzoneStyle : dropzoneStyle}
        >
          <input {...getInputProps()} />
          {
            isDragActive
              ? <p>Suelta los archivos aquí...</p>
              : fileList.length > 0
                ? <p>{fileList.length} archivo{fileList.length > 1 ? 's' : ''} PDF seleccionado{fileList.length > 1 ? 's' : ''}, suelta mas archivos</p>
                : <p>Arrastra y suelta archivos PDF aquí</p>
          }
        </div>

      </div>
    </div>
  );
};

