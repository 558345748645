import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchOrganization from "../../components/SearchAutoComplete/Organization/SearchOrganization";
import MainTableWithLocalizationProvider from '../../components/Tables/MainTable';
import { setSelectedOrganization } from "../../features/modalSlice/modalPolicySlice";
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Button } from '@mui/material';
import PolicyForm from '../../components/PolicyForm';
import PolicyHolderForm from '../../components/PolicyHolderForm';
import SubgroupForm from '../../components/SubgroupForm';
import OrganizationForm from '../../components/Organization/OrganizationForm';
import { clearSelectedOrganization } from '../../features/organization/organizationSlice';
function Policies() {
    const dispatch = useDispatch();
    const reduxSelectedOrganization = useSelector((state) => state.modalPolicy.selectedOrganization);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [showOrganizationForm, setShowOrganizationForm] = useState(false);
    const [showPolicyForm, setShowPolicyForm] = useState(false);
    const [showPolicyHolderForm, setShowPolicyHolderForm] = useState(false);
    const [showSubgroupForm, setShowSubgroupForm] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        dispatch(clearSelectedOrganization());
        setSelectedOrganization(null);

        if (reduxSelectedOrganization) {
            setSelectedOrganization(reduxSelectedOrganization.id);
        }
    }, [reduxSelectedOrganization, dispatch]);


    const handleSelect = (id) => {
        // Actualiza el estado local y el estado en Redux
        setSelectedOrganization(id);
    };
    const handleOrganizationOpen = () => {
        setShowOrganizationForm(true)
    }
    const handleOrganizationClose = () => {
        setShowOrganizationForm(false);
    };
    const handlePolicyOpen = () => {
        setShowPolicyForm(true)
    }
    const handlePolicyClose = () => {
        setShowPolicyForm(false);
    };
    const handlePolicyHolderOpen = () => {
        setShowPolicyHolderForm(true)
    }
    const handlePolicyHolderClose = () => {
        setShowPolicyHolderForm(false);
    };
    const handleSubgroupOpen = () => {
        setShowSubgroupForm(true)
    }
    const handleSubgroupClose = () => {
        setShowSubgroupForm(false);
    };
    const handleRefresh = () => {
        setRefreshKey(prevKey => prevKey + 1); // Cambia la clave para forzar el re-render
    };
    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div className="col-md-3">
                    <SearchOrganization onSelect={handleSelect} />
                </div>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleOrganizationOpen}
                    style={{ marginLeft: '1rem' }} // Añade un margen a la izquierda
                >
                    <IconButton>
                        <AddIcon />
                    </IconButton>
                    Organizacion
                </Button>

                {selectedOrganization && (
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePolicyHolderOpen}
                            style={{ marginLeft: '1rem' }} // Añade un margen a la izquierda
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            Contratante
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handlePolicyOpen}
                            style={{ marginLeft: '1rem' }} // Añade un margen a la izquierda
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            Poliza
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={handleSubgroupOpen}
                            style={{ marginLeft: '1rem' }} // Añade un margen a la izquierda
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            Subgrupo
                        </Button>
                    </div>
                )}
            </div>
            {selectedOrganization && (
                <div>
                    <MainTableWithLocalizationProvider key={refreshKey} selectedOrganization={selectedOrganization} />
                </div>
            )}
            {showOrganizationForm && (
                <OrganizationForm
                    buttonAddOrganization={true}
                    onClose={handleOrganizationClose}
                />
            )}
            {showPolicyHolderForm && (
                <PolicyHolderForm
                    buttonAddForeign={true}
                    organizationForeign={selectedOrganization}
                    onClose={handlePolicyHolderClose}
                />
            )}
            {showPolicyForm && (
                <PolicyForm
                    buttonAddForeign={showPolicyForm}
                    organizationForeign={selectedOrganization}
                    onClose={handlePolicyClose}
                    refresh={handleRefresh}
                />
            )}
            {showSubgroupForm && (
                <SubgroupForm
                    buttonAddForeign={showSubgroupForm}
                    organizationForeign={selectedOrganization}
                    onClose={handleSubgroupClose}
                />
            )}
        </div>
    );
};

export default Policies;
