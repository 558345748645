import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { sendCertificate, sendToAdminPolicy } from '../../api/CertificateService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



const ModalInsuredData = ({ showModal, handleClose, selectedId }) => {
  const MySwal = withReactContent(Swal);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name_paternal: '',
    last_name_maternal: '',
    work_email: '',
    rfc: '',
    curp: '',
    phone_office: '',
    phone_personal: '',
    rol: "Holder"
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Manejar el envío del formulario aquí
    console.log(formData);
    try {
      await sendCertificate(selectedId, formData);

      Swal.fire({
        title: '¡Éxito!',
        text: 'El certificado se ha enviado correctamente. ¿Quieres enviarlo al administrador de la póliza?',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar al administrador de la póliza',
        cancelButtonText: 'No, cerrar',
        icon: 'success',
      }).then((result) => {
        if (result.isConfirmed) {
          sendAdminCertificate(selectedId);
        } else {
          handleClose(); // Cierra el modal si no se desea enviar al administrador
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };

  const sendAdminCertificate = async (id) => {
    try {
      await sendToAdminPolicy(id);
      Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El certificado se ha enviado correctamente. ¿Quieres enviar a otra persona?',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar a otra persona',
        cancelButtonText: 'No, cerrar',
      }).then((result) => {
        if (result.isConfirmed) {
          sendAnotherEmail(id); // Vuelve a preguntar para enviar a otra persona
        } else {
          Swal.close(); // Cierra el Swal si no se desea enviar a otra persona
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };

  const sendAnotherEmail = async (id) => {
    try {
      // Solicitar el correo electrónico y enviar el certificado
      Swal.fire({
        icon: 'info',
        title: 'Enviar a otra persona',
        text: 'Introduce el email de la nueva persona',
        input: 'email',
        inputLabel: 'Correo electrónico',
        inputPlaceholder: 'Correo electrónico',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Enviar',
        inputValidator: (value) => {
          if (!value) {
            return 'El correo electrónico es obligatorio';
          }
          return null;
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newEmail = result.value;
          if (newEmail) {
            try {
              // Enviar el certificado al nuevo correo
              await sendToAdminPolicy(id, { email: newEmail });
              Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El certificado se ha enviado correctamente a la nueva persona.',
              }).then(() => {
                sendAnotherEmail(id); // Vuelve a preguntar para enviar a otra persona
              });
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al enviar el certificado.',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
              });
            }
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Completar Datos del Asegurado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFirstName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formLastNamePaternal">
            <Form.Label>Apellido Paterno</Form.Label>
            <Form.Control
              type="text"
              placeholder="Apellido Paterno"
              name="last_name_paternal"
              value={formData.last_name_paternal}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formLastNameMaternal">
            <Form.Label>Apellido Materno</Form.Label>
            <Form.Control
              type="text"
              placeholder="Apellido Materno"
              name="last_name_maternal"
              value={formData.last_name_maternal}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formWorkEmail">
            <Form.Label>Email del Trabajo</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email del Trabajo"
              name="work_email"
              value={formData.work_email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formRFC">
            <Form.Label>RFC</Form.Label>
            <Form.Control
              type="text"
              placeholder="RFC"
              name="rfc"
              value={formData.rfc}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formCURP">
            <Form.Label>CURP</Form.Label>
            <Form.Control
              type="text"
              placeholder="CURP"
              name="curp"
              value={formData.curp}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPhoneOffice">
            <Form.Label>Teléfono de Oficina</Form.Label>
            <Form.Control
              type="text"
              placeholder="Teléfono de Oficina"
              name="phone_office"
              value={formData.phone_office}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPhonePersonal">
            <Form.Label>Teléfono Personal</Form.Label>
            <Form.Control
              type="text"
              placeholder="Teléfono Personal"
              name="phone_personal"
              value={formData.phone_personal}
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInsuredData;