import React, { useState, useEffect } from 'react';
import { postOrganization, getOrganizations } from '../../api/OrganizationService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getHolderByOrganization, getPoliciesByHolder, postHolder } from '../../api/PolicyHolderService';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../../global';
import { Modal, Button, Form } from 'react-bootstrap';
import { RenderDropzone } from '../../features/pdfs/functionsPdf';
import { initialProcedureData } from "../../components/Helpers/initialData";
import { postProcedure } from '../../api/ProceduresService';
import { getContactsByOrganizationId } from '../../api/ContactsService';
import { getInsuredsByPolicyId } from '../../api/InsuredService';
import SearchOrganization from '../SearchAutoComplete/Organization/SearchOrganization';
import SearchPolicyHolder from '../SearchAutoComplete/PolicyHolder/SearchPolicyHolder';
import { types } from "../Helpers/policyData";

const MySwal = withReactContent(Swal);

function ProceduresForm({ buttonAddForeign, organizationForeign, onClose }) {
    const dispatch = useDispatch();
    const organization = useSelector((state) => state.organizations.data);
    const insurers = useSelector((state) => state.insurer.data);
    const executives = useSelector((state) => state.executive.data);

    const [orgDatas, setOrgDatas] = useState([]);
    const [executivesDatas, setExecutives] = useState([]);
    const [insurersDatas, setInsurers] = useState([]);

    const [orgDatasFiltered, setOrgDatasFiltered] = useState([]);

    const [buttonAdd, setButtonAdd] = useState(false);
    const [formData, setFormData] = useState(initialProcedureData);
    const [holders, setHolders] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [insureds, setInsureds] = useState([]);

    const [selectedOrganization, setSelectedOrganization] = useState([]);

    const [selectedHolder, setSelectedHolder] = useState('');

    useEffect(() => {

        if (!organization.length) {
            infoGlobal.fetchOrganizations();
        } else {
            setOrgDatas(organization);
        }
        if (!insurers.length) {
            infoGlobal.fetchInsurers();
        } else {
            setInsurers(insurers);
        }
        if (!executives.length) {
            infoGlobal.fetchExecutives();
        } else {
            setExecutives(executives);
        }
    }, [organization, dispatch, insurers, executives]);

    const upperCaseFields = ['internal_folio'];
    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        // Convertir a mayúsculas solo para campos específicos
        const newValue = upperCaseFields.includes(name) ? value.toUpperCase() : value;
        // Actualizar el estado
        setFormData({ ...formData, [name]: newValue });
    };
    const handleSelectOrganization = async (selectedOrgId) => {
        setFormData({ ...formData, ['organization_id']: selectedOrgId });
        try {
            const dataHolders = await getHolderByOrganization(selectedOrgId);
            setHolders(dataHolders.holders);
            const contactData = await getContactsByOrganizationId(selectedOrgId);
            setContacts(contactData.contacts);

        } catch (error) {
            alert(error.message)
        }
    }
    const handleSelectPolicyHolder = async (selectedId) => {
        setSelectedHolder(selectedId);
        setFormData({ ...formData, ['policy_holder_id']: selectedId });
        if (selectedId) {
            const dataPolicies = await getPoliciesByHolder(selectedId);
            setPolicies(dataPolicies);
        }
    }
    const handleSelectPolicy = async (event) => {
        const { name, value } = event.target;
        if (formData.procedure_type == 'AA' && value) {
            const dataInsureds = await getInsuredsByPolicyId(value)
            console.log("dataInsureds", dataInsureds)
            setInsureds(dataInsureds.insureds)
        }
        const selectedPolicy = policies.find(policy => policy.id_policy === parseInt(value));
        setFormData({
            ...formData,
            insurer_id: selectedPolicy.insurer.id_insurer,
            executive_id: selectedPolicy.executive.id_executive,
            policy_branch: selectedPolicy.policy_branch,
            policy_type: selectedPolicy.policy_type,
            [name]: value
        });

    }
    const handleSelectType = async (event) => {
        const { name, value } = event.target;
        console.log(event.target.value)
        if (value == 'EI') {
            setFormData({
                ...formData,
                movement_type: "Emision Inicial",
                [name]: value
            });
        } else {
            setFormData({
                ...formData,
                movement_type: "Endoso",
                [name]: value
            });
        }
    }
    const handleOnclose = () => {
        onClose()
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await postProcedure(formData);
            console.log(response)

            MySwal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El tramite se ha añadido correctamente.',
                footer: `<pre>Con el numero de folio: ${response.internal_folio}</pre>`,

            }).then(() => {
                setButtonAdd(false); // Después de enviar el formulario, cambia el estado de botonAdd a false
                setFormData(initialProcedureData);
            });
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
            });
        }
    };
    const handleFileChange = (updatedFiles, category) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [category]: updatedFiles
        }));
        console.log(category, updatedFiles);
    };
    return (
        <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto">
            <div className="row">
                {/* Datos Principales */}
                {/* Organización */}
                <div className="row">
                    {/* Organización */}
                    <div className="col-md-2 mb-3">
                        <SearchOrganization onSelect={handleSelectOrganization} />
                    </div>

                    {/* Contratante */}
                    <div className="col-md-2 mb-3">
                        <SearchPolicyHolder onSelect={handleSelectPolicyHolder} organizationForeign={holders} />
                    </div>

                    {/* Poliza */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="policy_id"
                                value={formData.policy_id}
                                onChange={handleSelectPolicy}
                                className="form-select"
                                id="floatingPolicySelect"
                                style={{ fontSize: 'inherit' }}

                            >
                                <option value="">Selecciona una Poliza</option>
                                {policies.map(policy => (
                                    <option key={policy.id_policy} value={policy.id_policy}>
                                        {policy.policy_number}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="floatingPolicySelect">Poliza</label>
                        </div>
                    </div>
                    {/* Aseguradora */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="insurer_id"
                                value={formData.insurer_id}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingInsurerId"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona una Aseguradora</option>
                                {insurersDatas.map(insurer => (
                                    <option key={insurer.id_insurer} value={insurer.id_insurer}>
                                        {insurer.id_insurer} - {insurer.commercial_name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="floatingInsurerId">Aseguradora</label>
                        </div>
                    </div>



                    {/* Ejecutivo */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="executive_id"
                                value={formData.executive_id}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingExecutiveId"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona un Ejecutivo</option>
                                {executivesDatas.map(executive => (
                                    <option key={executive.id_executive} value={executive.id_executive}>
                                        {executive.id_executive} - {executive.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="floatingExecutiveId">Ejecutivo</label>
                        </div>
                    </div>



                    {/* Rama */}
                    <div className="col-md-2 mb-3 ">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="policy_branch"
                                value={formData.policy_branch}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingPolicyBranch"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona una Rama</option>
                                <option value="GMM">GMM</option>
                                <option value="Auto">Auto</option>
                                <option value="Casa">Casa</option>
                                <option value="Daños">Daños</option>
                            </select>
                            <label htmlFor="floatingPolicyBranch">Rama</label>
                        </div>
                    </div>
                    <div className="col-md-2 mb-3 ">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="policy_type"
                                value={formData.policy_type}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingPolicyType"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona un tipo de poliza</option>
                                {types.map(option => (
                                    <option key={option.name} value={option.name}>{option.name}</option>
                                ))}
                            </select>
                            <label htmlFor="floatingPolicyType">Tipo de poliza</label>
                        </div>
                    </div>
                    <hr />
                    {/* Tipo */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="procedure_type"
                                value={formData.procedure_type}
                                onChange={handleSelectType}
                                className="form-select"
                                id="floatingProcedureType"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona un Movimiento</option>
                                <option value="RA">Reconocimiento de Antigüedad</option>
                                <option value="AA">Alta de Asegurado</option>
                                <option value="BA">Baja de Asegurado</option>
                                <option value="CD">Corrección en Datos</option>
                                <option value="EI">Emisión Inicial</option>
                            </select>
                            <label htmlFor="floatingProcedureType">Tipo</label>
                        </div>
                    </div>
                    {/* Asegurado */}
                    {(formData.procedure_type === 'BA' || formData.procedure_type === 'RA' || formData.procedure_type === 'CD') && insureds && (
                        <div className="col-md-2 mb-3">
                            <div className="form-floating" style={{ fontSize: '14px' }}>
                                <select
                                    name="insured_id"
                                    value={formData.insured_id}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ fontSize: 'inherit' }}
                                    id="floatingInsuredId"
                                >
                                    <option value="">Selecciona un asegurado</option>
                                    {insureds.map(insured => (
                                        <option key={insured.id_insured} value={insured.id_insured}>
                                            {insured.complete_name}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor="floatingInsuredId">Asegurado</label>
                            </div>
                        </div>
                    )}
                    {/* Quien Solicita */}
                    <div className="col-md-2 mb-3 ">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="contact_id"
                                value={formData.contact_id}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingContactId"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona un Contacto</option>
                                {contacts.map(contact => (
                                    <option key={contact.id_contact} value={contact.id_contact}>
                                        {contact.id_contact} - {contact.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="floatingContactId">Quien Solicita</label>
                        </div>
                    </div>

                    {/* Medio de Solicitud */}
                    <div className="col-md-2 mb-3 ">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="request_method"
                                value={formData.request_method}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingRequestMethod"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona un Medio de Solicitud</option>
                                <option value="Email">Email</option>
                                <option value="Whatsapp">Whatsapp</option>
                                <option value="Teléfono">Teléfono</option>
                                <option value="Reunión">Reunión</option>
                                <option value="Otro">Otro</option>
                            </select>
                            <label htmlFor="floatingRequestMethod">Medio de Solicitud</label>
                        </div>
                    </div>

                    {/* Status */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                                className="form-select"
                                id="floatingStatus"
                                style={{ fontSize: 'inherit' }}
                                required
                            >
                                <option value="">Selecciona un Estatus</option>
                                <option value="Inicio">Inicio</option>
                                <option value="En proceso">En proceso</option>
                                <option value="Detenido">Detenido</option>
                                <option value="Folio Pendiente">Folio Pendiente</option>
                                <option value="Documentacion adicional">Documentacion adicional</option>
                                <option value="Finalizado">Finalizado</option>
                                <option value="Otro">Otro</option>
                            </select>
                            <label htmlFor="floatingStatus">Estatus</label>
                        </div>
                    </div>

                    {/* Tipo de Movimiento */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <select
                                name="movement_type"
                                value={formData.movement_type}
                                onChange={handleChange}
                                className="form-select"
                                style={{ fontSize: 'inherit' }}
                                id="floatingMovement_Type"
                            >
                                <option value="">Selecciona un Movimiento</option>
                                <option value="Endoso">Endoso</option>
                                <option value="Emision Inicial">Emisión Inicial</option>
                            </select>
                            <label htmlFor="floatingMovement_Type">Tipo de Movimiento</label>
                        </div>
                    </div>

                    {/* No. De Endoso */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <input
                                type="text"
                                name="endorsement_number"
                                value={formData.endorsement_number}
                                onChange={handleChange}
                                className="form-control"
                                id="floatingEndorsementNumber"
                                style={{ fontSize: 'inherit' }}
                                placeholder=""
                            />
                            <label htmlFor="floatingEndorsementNumber">No. De Endoso</label>
                        </div>
                    </div>
                    {/* Folio Aseguradora */}
                    <div className="col-md-2 mb-3">
                        <div className="form-floating" style={{ fontSize: '14px' }}>
                            <input
                                type="text"
                                name="insurer_folio"
                                value={formData.insurer_folio}
                                onChange={handleChange}
                                className="form-control"
                                id="floatingInsurerFolio"
                                style={{ fontSize: 'inherit' }}
                                placeholder=" "
                            />
                            <label htmlFor="floatingInsurerFolio">Folio Aseguradora</label>
                        </div>
                    </div>
                </div>

                <hr />


                {/* F. De Solicitud X Cliente */}
                <div className="col-md-2 mb-3">
                    <div className="form-floating" style={{ fontSize: '14px' }}>
                        <input
                            type="date"
                            name="client_request_date"
                            value={formData.client_request_date}
                            onChange={handleChange}
                            className="form-control"
                            id="floatingClientRequestDate"
                            placeholder=" "
                        />
                        <label htmlFor="floatingClientRequestDate">F. De solicitud X Cliente</label>
                    </div>
                </div>

                {/* F. De Solicitud en Aseguradora */}
                <div className="col-md-2 mb-3">
                    <div className="form-floating" style={{ fontSize: '14px' }}>
                        <input
                            type="date"
                            name="insurer_request_date"
                            value={formData.insurer_request_date}
                            onChange={handleChange}
                            className="form-control"
                            id="floatingInsurerRequestDate"
                            placeholder=" "
                        />
                        <label htmlFor="floatingInsurerRequestDate">F. De Solicitud en Aseguradora</label>
                    </div>
                </div>

                {/* F. Estimada */}
                <div className="col-md-2 mb-3">
                    <div className="form-floating" style={{ fontSize: '14px' }}>
                        <input
                            type="date"
                            name="expected_date"
                            value={formData.expected_date}
                            onChange={handleChange}
                            className="form-control"
                            id="floatingInsurerRequestDate"
                            placeholder=" "
                        />
                        <label htmlFor="floatingInsurerRequestDate">F. Estimada</label>
                    </div>
                </div>
                {/* Comentarios */}
                <div className="col-md-6 mb-3">
                    <div className="form-floating" style={{ fontSize: '14px' }}>
                        <textarea
                            name="comments"
                            value={formData.comments}
                            onChange={handleChange}
                            className="form-control"
                            id="floatingComments"
                            placeholder=" "
                            style={{ height: '40px' }} />
                        <label htmlFor="floatingComments">Comentarios</label>
                    </div>
                </div>
                {/* Otros */}
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <RenderDropzone
                            onFilesChange={handleFileChange}
                            fileName="Otros"
                            category="pdf_others"
                            files={formData.pdf_others}
                        />
                    </div>

                    <div className="col-md-6 mb-3">
                        <RenderDropzone
                            onFilesChange={handleFileChange}
                            fileName="Recibos"
                            category="receipts"
                            files={formData.receipts}
                        />
                    </div>
                </div>



            </div>
            <div className='d-flex justify-content-end'>
                <button type="submit" className="btn btn-success me-3 btn-lg">Guardar</button>
                <button type="button" className="btn btn-danger btn-lg" onClick={handleOnclose}>Cancelar</button>
            </div>


        </form>
    );


}

export default ProceduresForm;
