import React, { useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { postPools } from '../../../features/pools/poolService';
import { Modal } from 'react-bootstrap';
const MySwal = withReactContent(Swal);

function PoolForm({ buttonAddForeign, onClose }) {
  const [buttonAdd, setbuttonAdd] = useState(false);
  const [formData, setFormData] = useState({ name: '' });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    postPools(formData)
    setbuttonAdd(false); // Después de enviar el formulario, cambia el estado de buttonAdd a false
    setFormData({ name: '' }); // Limpia los datos del formulario
    if(buttonAddForeign){
      onClose();
    }
    MySwal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: 'Se ha añadido correctamente.',
    });
  };


  if (!buttonAdd && !buttonAddForeign) {
    return (
      <div>
        {!buttonAdd && (
          <div>
            <h2> Pools</h2>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success w-100" onClick={() => setbuttonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }
  if (buttonAddForeign) {
    return (
      <Modal dialogClassName="modal-lg" show={true} onHide={() => onClose()}>
        <form onSubmit={handleSubmit} className="container mt-4">
          <Modal.Header closeButton>
            <Modal.Title>Añadir Pool</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" placeholder="Nombre del pool" required />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-danger" onClick={() => onClose()}>Cancelar</button>
            <button type="submit" className="btn btn-primary me-3">Guardar</button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
  return (
    <form onSubmit={handleSubmit} className="w-100 px-3 mx-auto mt-4">
      <h2> Pools</h2>

      <div className="mb-3">
        <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" placeholder="Nombre" />
      </div>


      <button type="submit" className="btn btn-primary">Submit</button>
      <button className="btn btn-danger" onClick={() => setbuttonAdd(false)}>Cancelar</button>

    </form>
  );
}


export default PoolForm;
