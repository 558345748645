import React, { useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, lighten, IconButton } from '@mui/material';
import MoreSharpIcon from '@mui/icons-material/MoreSharp';
import { useDispatch } from 'react-redux';
import { getCertificatesByPolicyId } from '../../../api/CertificateService.js';
import ModalActionsCertificate from './ModalActionsCertificate.js';

// Función para transformar datos
const transformData = (data) => {
  return {
    ...data,
    status: data.status === 'released' ? 'Liberado' : data.status === 'pending' ? 'En proceso' : data.status,
    holder: data.holder || 'Sin Titular',
  };
};

const MainTable = ({ policy_id }) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Estado para controlar la fila seleccionada
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar la visibilidad del modal
  const [policyNumber, setPolicyNumber] = useState(null); 
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [policy_id]);

  const fetchData = async () => {
    try {
      const response = await getCertificatesByPolicyId(policy_id);
      const transformedData = response.certificates.map(transformData); // Transforma los datos
      setPolicyNumber(response.policy_number);
      setData(transformedData);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'details',
        header: 'Detalles',
        columns: [
          { accessorKey: 'certificate_number', header: 'Certificado', size: 50 },
          { accessorKey: 'status', header: 'Estatus', size: 50 },
          { accessorKey: 'holder', header: 'Titular', size: 30 },
          { accessorKey: 'subgroup_number', header: 'Numero subgrupo', size: 20 },
          { accessorKey: 'subgroup_name', header: 'Nombre subgrupo', size: 50 },
        ],
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    console.log('row', row)
    setSelectedRow(row); // Establece la fila seleccionada
    setModalOpen(true); // Abre el modal
  };

  const table = useMaterialReactTable({
    columns: columns[0].columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton color="primary" onClick={() => handleRowClick(row.original)}>
          <MoreSharpIcon fontSize="large" />
        </IconButton>
      </Box>
    ),
    muiFilterTextFieldProps: ({ column }) => ({
      label: `${column.columnDef.header}`,
    }),
    enableRowSelection: false,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      placeholder: 'Busqueda Global',
      size: 'medium',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderTopToolbar: ({ table }) => {
      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert('Desactivando ' + row.getValue('policy_number'));
        });
      };

      const handleActivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert('Activando ' + row.getValue('policy_number'));
        });
      };

      const handleContact = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert('Contactando ' + row.getValue('policy_number'));
        });
      };

      return (
        <div>
          <h2>
            Certificados de la Poliza: {policyNumber}
          </h2>
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <Button
                  color="error"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleDeactivate}
                  variant="contained"
                >
                  Desactivar
                </Button>
                <Button
                  color="success"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleActivate}
                  variant="contained"
                >
                  Activar
                </Button>
                <Button
                  color="info"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleContact}
                  variant="contained"
                >
                  Contactar
                </Button>
              </Box>
            </Box>
          </Box>
        </div>

      );
    },
  });

  return (
    <>
      <MaterialReactTable
        table={table}
        columns={columns[0].columns.map((col) => ({
          ...col,
        }))}
      />

      {modalOpen && selectedRow && (
        <ModalActionsCertificate
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          data={selectedRow}
        />
      )}
    </>
  );
};

const MainTableCertificates = ({ policy_id }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MainTable policy_id={policy_id} />
  </LocalizationProvider>
);

export default MainTableCertificates;
