import React, { useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, lighten, Button, MenuItem, IconButton, Menu } from '@mui/material';

import { Spinner, Alert, Modal, Form } from 'react-bootstrap';
import { MoreVert, Sync } from '@mui/icons-material'; // Ícono de tres puntos

import { getCertificatesByPolicyId, uploadCertificate } from '../../../api/CertificateService.js';
//import ModalActionsCertificate from './ModalActionsCertificate.js';
import { deleteAllInsureds, deleteInsured, getInsuredsByPolicyId, sendMassiveCertificates } from '../../../api/InsuredService.js';
import { sendEmailCertificate, sendEmailPolicy, sendInvitation } from '../../../api/emailService.js';

import { pdfLogo, seguWalletLogo } from '../../../global.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faMagnifyingGlass, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Button as ButtonBoostrap } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import ModalLogs from './ModalLogs.js';
import Swal from 'sweetalert2';
import ModalAddEmail from './ModalAddEmail.js';

import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Edit, Delete } from '@mui/icons-material';
import ModalEditInsured from './ModalEditInsured.js';

// Función para transformar datos
const transformData = (type, key) => {
  const mappings = {
    gender: {
      'M': 'Masculino',
      'F': 'Femenino',
      'masculino': 'Masculino',
      'femenino': 'Femenino',
    },
    relationship: {
      'holder': 'Titular',
      'dependent': 'Dependiente',
      'spouse': 'Cónyuge',
    },
  };
  return mappings[key]?.[type] || type || 'Error';
};
const calculateAge = (dateOfBirth) => {
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // Si no ha llegado el cumpleaños este año, resta un año
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const MainTable = ({ policy }) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Estado para controlar la fila seleccionada
  const [modalLog, setModalLog] = useState(false); // Estado para controlar la visibilidad del modal
  const [policyNumber, setPolicyNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar el estado de carga
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCertificateId, setSelectedCertificateId] = useState(null);
  const [selectedInsuredId, setSelectedInsuredId] = useState(null);
  const [selectedInsured, setSelectedInsured] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // Estado para manejar anchorEl por fila
  const [anchorEls, setAnchorEls] = useState({});

  // Función para manejar la apertura del menú de una fila específica
  const handleClick = (event, rowId) => {
    setAnchorEls(prev => ({ ...prev, [rowId]: event.currentTarget }));
  };

  // Función para manejar el cierre del menú de una fila específica
  const handleClose = (rowId) => {
    setAnchorEls(prev => ({ ...prev, [rowId]: null }));
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [policy]);

  const fetchData = async () => {
    try {
      console.log(policy)
      const response = await getInsuredsByPolicyId(policy.id_policy);
      setPolicyNumber(response.policy_number);
      setData(response.insureds);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendMassiveCertificates = async () => {
    try {
      setIsLoading(true);
      const allInsureds = table.getRowModel().rows.map(row => row.original);
      console.log(allInsureds);
      const formData = {
        insureds: allInsureds,
        type: 'certificates',
      };
      const response = await sendMassiveCertificates(formData);
      console.log('response', response);
      setIsLoading(false);
      fetchData();
      alert(`Correos Enviados: ${response.sended}, Correos Buscados: ${response.searched}`);
      let missingAlerts = [];

      response.missing_details.forEach(insured => {
        let missingData = [];
        if (insured.missing.email) {
          missingData.push("email");
        }
        if (insured.missing.pdf) {
          missingData.push("pdf");
        }
        if (missingData.length > 0) {
          missingAlerts.push(`Asegurado: ${insured.complete_name}, le falta: ${missingData.join(", ")}`);
        }
      });
      if (missingAlerts.length > 0) {
        alert(missingAlerts.join("\n"));
      }
    } catch (error) {
      console.log("error al enviar certificados", error);
    }
  };

  const handleSendMassiveInvitations = async () => {
    try {
      setIsLoading(true);
      const allInsureds = table.getRowModel().rows.map(row => row.original);
      console.log(allInsureds);
      const formData = {
        insureds: allInsureds,
        type: 'seguwallet',
      };
      const response = await sendMassiveCertificates(formData);
      console.log('response', response);
      setIsLoading(false);
      fetchData();
      alert(`Correos Enviados: ${response.sended}, Correos Buscados: ${response.searched}`);
      let missingAlerts = [];

      response.missing_details.forEach(insured => {
        let missingData = [];
        if (insured.missing.email) {
          missingData.push("email");
        }
        if (insured.missing.pdf) {
          missingData.push("pdf");
        }
        if (missingData.length > 0) {
          missingAlerts.push(`Asegurado: ${insured.complete_name}, le falta: ${missingData.join(", ")}`);
        }
      });
      if (missingAlerts.length > 0) {
        alert(missingAlerts.join("\n"));
      }
    } catch (error) {
      console.log("error al enviar certificados", error);
    }
  };

  const handleSendMassiveEmails = async () => {
    Swal.fire({
      title: '¿Deseas enviar correos a todos los asegurados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, enviar',
      cancelButtonText: 'Cancelar',
      footer: `Se enviara: Certificado y la invitacion a SeguWallet`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const allInsureds = table.getRowModel().rows.map(row => row.original);
          console.log(allInsureds);
          const formData = {
            insureds: allInsureds,
            type: 'both',
          };
          const response = await sendMassiveCertificates(formData);
          console.log('response', response);
          setIsLoading(false);
          fetchData();
          alert(`Correos Enviados: ${response.sended}, Correos Buscados: ${response.searched}`);
          let missingAlerts = [];

          response.missing_details.forEach(insured => {
            let missingData = [];
            if (insured.missing.email) {
              missingData.push("email");
            }
            if (insured.missing.pdf) {
              missingData.push("pdf");
            }
            if (missingData.length > 0) {
              missingAlerts.push(`Asegurado: ${insured.complete_name}, le falta: ${missingData.join(", ")}`);
            }
          });
          if (missingAlerts.length > 0) {
            alert(missingAlerts.join("\n"));
          }
        } catch (error) {
          console.log("error al enviar certificados", error);
        }
      }
    });
  };

  const handleAdd = (row, type) => {
    console.log(row);
    try {
      if (type === 'email') {
        console.log("Email", row)

        setSelectedRow(row);
        setShowAddEmail(true);
      } else if (type === 'attach') {
        setIsLoading(true);
        setSelectedCertificateId(row.certificate_id);
        setShowUploadModal(true);
      }
    } catch (error) {
      console.log("ERROR TEST", error)
      alert('Error enviando el correo: ' + error.response.data.messag); // Mensaje en caso de error
    } finally {
      setIsLoading(false); // Detener el estado de carga
    }
  };

  const closeModal = () => {
    setShowUploadModal(false);
    setShowAddEmail(false);
    setSelectedFile(null);
    setSelectedCertificateId(null);
    setShowEditModal(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const response = await uploadCertificate(selectedCertificateId, selectedFile);
      console.log(response);

      Swal.fire({
        title: 'Éxito',
        text: 'Certificado subido correctamente!',
        icon: 'success',
      }).then(() => {
        fetchData();
        closeModal();
      });

    } catch (error) {
      console.error('Error al subir el certificado:', error);
      Swal.fire({
        title: 'Error',
        text: 'Ocurrió un error al intentar subir el certificado.',
        icon: 'error',
      });
    }
  };

  const handleOpenModalEmailLog = () => {
    setModalLog(true);
  };

  const handleOpenModalEdit = () => {
    console.log("Log handleOpenModalEdit", selectedRow);
    setShowEditModal(true);
  };
  const handleDeleteInsured = async (id_insured) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "¡Solo se borrará el asegurado seleccionado!, No se borrara toda la familia",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, borrar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        await deleteInsured(id_insured);
        Swal.fire('¡Borrado!', 'El asegurado ha sido borrado.', 'success');
        fetchData();

      } catch (error) {
        Swal.fire('Error', 'Hubo un problema al borrar el asegurado.', 'error');
      }
    }
  };
  const handleDeleteAllInsured = async () => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Se borraran todos los asegurados de esta poliza",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, borrar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        await deleteAllInsureds(policy.id_policy);
        Swal.fire('¡Borrado!', 'Se han borrado los asegurados de esta poliza', 'success');
        fetchData();

      } catch (error) {
        Swal.fire('Error', 'Hubo un problema al borrar los asegurados.', 'error');
      }
    }
  };

  const handleSendEmail = async (row, type) => {
    const email = row.email;
    const work_email = row.work_email;
    const name = row.first_name;
    const insured = row.id_insured;

    console.log("tipo de poliza 2", row.policy_type)
    console.log("tipo de poliza 2", row)
    const formData = {
      email: email,
      work_email: work_email,
      first_name: name,
      id_insured: insured,
      policy_type: row.policy_type,
      last_name_paternal: row.last_name_paternal,
      last_name_maternal: row.last_name_maternal,
      date_birth: row.date_birth
    };

    try {
      if (type === 'certificate') {
        setIsLoading(true);
        if (row.policy_type == 'colectivo') {
          console.log("tipo", "colectivo")

          await sendEmailCertificate(row.certificate_id, formData);
        } else if (row.policy_type == 'individual') {
          console.log("tipo", "indi")
          await sendEmailPolicy(row.policy_id, formData)
        }
        setIsLoading(false);
        alert('Correo de certificado enviado correctamente');
      } else if (type === 'seguwallet') {
        console.log("ROW", row)
        setIsLoading(true);
        await sendInvitation(row.certificate_id ?? row.policy_id, formData);
        alert('Invitación SeguWallet enviada correctamente');
      }
    } catch (error) {
      console.log("ERROR TEST", error)

      alert('Error enviando el correo: ' + error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const openUploadModal = (selectedCertificateId) => {
    setSelectedCertificateId(selectedCertificateId); // Guardar el ID del certificado
    setShowUploadModal(true);
  };


  const handleExportData = () => {
    const allInsureds = table.getRowModel().rows.map(row => row.original);

    const filteredData = allInsureds.map(item => ({
      id_insured: item.id_insured,
      rol: item.rol,
      first_name: item.first_name,
      last_name_paternal: item.last_name_paternal,
      last_name_maternal: item.last_name_maternal,
      email: item.email,
      work_email: item.work_email,
      rfc: item.rfc,
      curp: item.curp,
      phone_office: item.phone_office,
      phone_personal: item.phone_personal,
      relationship: item.relationship,
      date_birth: item.date_birth,
      gender: item.gender,
      current_antiquity: item.current_antiquity,
      total_antiquity: item.total_antiquity,
      complete_name: item.complete_name,
      certificate_number: item.certificate_number,
      subgroup_number: item.subgroup_number,
      subgroup_name: item.subgroup_name,
      certificate_id: item.certificate_id,
      created_at: item.created_at,
    }));

    const csv = generateCsv(csvConfig)(filteredData);
    download(csvConfig)(csv);
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'subgroup_name', header: <div>Subgrupo</div>, size: 20, Cell: ({ cell }) => { const value = cell.getValue(); return value ? value : 'NA'; } },
      { accessorKey: 'certificate_number', header: <div>Certificado</div>, size: 20, Cell: ({ cell }) => { const value = cell.getValue(); return value ? value : 'NA'; } },
      { accessorKey: 'complete_name', header: <div>Nombre</div>, size: 50 },
      { accessorKey: 'gender', header: <div>Género</div>, size: 20, Cell: ({ cell }) => transformData(cell.getValue(), 'gender') },
      { accessorKey: 'relationship', header: <div>Relación</div>, size: 20, Cell: ({ cell }) => transformData(cell.getValue(), 'relationship') },
      {
        accessorFn: (row) => row.email || row.work_email,
        header: 'Correo',
        size: 50,
        show: false,
      },
      {
        id: 'pdf_certificate',
        header: 'Archivo',
        size: 50,
        accessorFn: (row) => {
          const pdfs = row?.pdfs || []; // Obtener PDFs
          return pdfs.map((pdf) => (
            <a key={pdf?.id} href={pdf?.public_link} target="_blank" rel="noopener noreferrer">
              <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
            </a>
          ));
        },
      },
      {
        accessorKey: 'date_birth',
        header: <div>Edad</div>,
        size: 20,
        Cell: ({ cell }) => {
          const dateOfBirth = cell.getValue();
          const age = calculateAge(dateOfBirth);
          return <div>{age} años</div>;
        }
      },

      { accessorKey: 'date_birth', header: <div>Nacimiento</div>, size: 20, Cell: ({ cell }) => transformData(cell.getValue()) },
      { accessorKey: 'current_antiquity', header: <div>Antiguedad</div>, size: 20, Cell: ({ cell }) => transformData(cell.getValue()) },
      { accessorKey: 'created_at', header: <div></div>, size: 0 },
      {
        id: 'action_Log',
        size: 5,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        enableResizing: false,
        Cell: ({ row }) => {
          const rowId = row.original.id_insured;
          const isMenuOpen = Boolean(anchorEls[rowId]);
          return (
            <div>
              {/* <MenuItem
                onClick={() => {
                  console.log("Seleccion 1", row.original);
                  setSelectedRow(row.original);
                  handleOpenModalEmailLog();
                  handleClose(rowId);
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} size='lg' style={{ marginRight: '5px' }} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedRow(row.original);
                  handleOpenModalEdit();
                  handleClose(rowId);
                }}
              >
                <Edit />
              </MenuItem> */}

              {/* Botón de menú con más acciones */}
              <IconButton
                id={`basic-button-${rowId}`}
                aria-controls={isMenuOpen ? `basic-menu-${rowId}` : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                onClick={(event) => handleClick(event, rowId)} // Pasa el ID de la fila
              >
                <MoreVert />
              </IconButton>

              {/* Menú de opciones */}
              <Menu
                id={`basic-menu-${rowId}`}
                anchorEl={anchorEls[rowId]}
                open={isMenuOpen}
                onClose={() => handleClose(rowId)}
                MenuListProps={{
                  'aria-labelledby': `basic-button-${rowId}`,
                }}
              >
                <MenuItem
                  onClick={() => {
                    setSelectedRow(row.original);
                    handleOpenModalEmailLog();
                    handleClose(rowId);
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} size='lg' />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedRow(row.original);
                    handleOpenModalEdit();
                    handleClose(rowId);
                  }}
                >
                  <Edit />
                </MenuItem>
                {policy?.policy_type !== 'GMM Individual' && (

                  <MenuItem
                    onClick={() => {
                      console.info('Delete');
                      handleDeleteInsured(row.original.id_insured);
                      console.log('row.original', row.original.id_insured)
                      handleClose(rowId);
                    }}
                  >
                    <Delete />
                  </MenuItem>
                )}
              </Menu>
            </div>
          );
        }
      },
    ],
    [anchorEls]
  );

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `Asegurados-${new Date().toLocaleDateString('es-ES')}`
  });

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,

    enableRowSelection: false,
    enableMultiRowSelection: false,
    initialState: {
      density: 'compact',
      sorting: [
        { id: 'created_at', desc: true },
      ],
      columnVisibility: { created_at: false },
      pagination: {
        pageSize: 20,
      },
      positionPagination: 'both',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['action_Log'], // Fijar la columna de acciones al inicio
        right: ['mrt-row-actions'], // Fijar la columna de acciones al final
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <div>
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <ButtonBoostrap onClick={fetchData} variant="success">
                  <Sync />
                </ButtonBoostrap>
                {/* Botones para acciones masivas */}
                <ButtonBoostrap
                  onClick={handleSendMassiveEmails} // Añadido botón para enviar correos masivos
                  variant="secondary"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> :
                    <div>
                      <div>
                        <FontAwesomeIcon icon={faPaperPlane} size="lg" />
                        Envio Masivo de Correos
                      </div>
                    </div>
                  }
                </ButtonBoostrap>
                <ButtonBoostrap onClick={handleExportData} variant="success">
                  <FileDownloadIcon />
                  Exportar a Excel
                </ButtonBoostrap>
                <ButtonBoostrap
                  onClick={handleDeleteAllInsured}
                  variant="danger"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> :
                    <div>
                      <DeleteForeverIcon />
                    </div>
                  }
                </ButtonBoostrap>


              </Box>
            </Box>
          </Box>
        </div>
      );
    },
    displayColumnDefOptions: {
      'mrt-row-actions': { header: 'Envios' },
    },
    renderRowActions: ({ row }) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            {((row.original.email || row.original.work_email) && row.original.pdfs?.length > 0) && (
              <div>
                <button className="btn btn-sm mb-2"
                  onClick={() => handleSendEmail(row.original, "certificate")}
                  title="Enviar certificado"
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> :
                    <FontAwesomeIcon icon={faPaperPlane} size="2x" />
                  }
                </button>
                <button
                  className="btn"
                  onClick={() => handleSendEmail(row.original, "seguwallet")}
                  style={{ alignItems: 'start', justifyContent: 'start', padding: '0' }}
                  title="Enviar invitacion a SeguWallet"
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> :
                    <img src={seguWalletLogo} alt="Icono" style={{ width: '50%'}} />
                  }
                </button>
              </div>
            )}
          </Box>

          <Box>
            {/* Añadir Email */}
            <div>
              {(!row.original.email && !row.original.work_email) && (
                <button className="btn btn-sm me-1 mb-2"
                  onClick={() => handleAdd(row.original, "email")}
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faAt} size='2x' />}
                </button>
              )}
              {!row.original.pdfs?.length > 0 && (
                <button className="btn btn-sm me-1"
                  onClick={() => handleAdd(row.original, "attach")}
                >
                  {isLoading ? <Spinner animation="border" size="sm" /> :
                    <FontAwesomeIcon icon={faPaperclip} size='2x' />
                  }
                </button>
              )}

            </div>
          </Box>
        </div>
      );
    },
    muiFilterTextFieldProps: ({ column }) => ({
      label: `${column.columnDef.header}`,
    }),
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      placeholder: 'Busqueda',
      size: 'medium',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [20, 30, 40],
      shape: 'rounded',
      variant: 'outlined',
    },
  });

  return (
    <div>
      <MaterialReactTable
        table={table}
      />

      {modalLog && selectedRow && (
        <ModalLogs
          show={modalLog}
          onHide={() => setModalLog(false)}
          data={selectedRow}
        />
      )}
      {selectedRow && (
        <div>
          <ModalAddEmail showModal={showAddEmail} handleClose={closeModal} insured={selectedRow} updateList={fetchData} />
          <ModalEditInsured showModal={showEditModal} handleClose={closeModal} insured={selectedRow} updateList={fetchData} />
        </div>
      )}
      <Modal show={showUploadModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Subir Certificado PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Seleccionar archivo PDF:</Form.Label>
            <input
              type="file"
              accept="application/pdf"
              multiple={false}
              className="form-control"
              onChange={handleFileChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBoostrap variant="secondary" onClick={closeModal}>
            Cancelar
          </ButtonBoostrap>
          {selectedFile && (
            <ButtonBoostrap variant="primary" accept="application/pdf" onClick={handleUpload}>
              Subir
            </ButtonBoostrap>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const MainTableInsured = ({ policy }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MainTable policy={policy} />
  </LocalizationProvider>
);

export default MainTableInsured;
