import React, { useState, useEffect } from 'react';
import { getHolders, updateHolder, deleteHolder, getHolderByOrganization } from '../api/PolicyHolderService';
import { getPoliciesByHolder } from '../api/policyService';
import { deleteSubgroup, getSubgroupByPolicyId, updateSubgroup } from "../api/SubgroupService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PolicyHolderForm from './PolicyHolderForm';
import { pdfLogo } from "../global";
import SubgroupForm from './SubgroupForm';
import { deletePDF, uploadPDF } from '../api/PdfService';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';
import { getOrganizations } from '../api/OrganizationService';
import { useDropzone } from 'react-dropzone';
import { getRole } from '../api/userService';

import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
import SearchOrganization from './SearchAutoComplete/Organization/SearchOrganization';
import SubgroupExcelUpload from './Excel/SubgroupExcelUpload';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

const MySwal = withReactContent(Swal);

function SubgroupList() {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organizations.data);
  const user = useSelector((state) => state.users.data);

  const { getRootProps, getInputProps, isDragActive } = useDropzone();

  const [orgDatas, setOrgDatas] = useState([]);
  const [policyHolders, setPolicyHolders] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [formData, setFormData] = useState({ "pdfs": [] });
  const [subgroups, setSubgroups] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [originalData, setOriginalData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedPolicyHolder, setSelectedPolicyHolder] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [role] = useState('')
  const canDeleteOrCreate = user === 'admin' || user === 'executive';
  const [excelUploadOpen, setExcelUploadOpen] = useState(false);
  const [subgroupId, setSubgroupId] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);

  useEffect(() => {
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization)
    }
    if (!user.length) {
      infoGlobal.fetchRole();
    }
  }, [organization, user, dispatch]);

  const fetchOrganizations = async () => {
    try {
      const orgData = await getOrganizations();
      setOrgDatas(orgData.organizations);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };
  const handleOrganizationChange = async (selectedOrgId) => {
    try {
      setSelectedOrganization(selectedOrgId);
      console.log("ID DESDE ORG>JS PRUEBNA", selectedOrgId)
      const data = await getHolderByOrganization(selectedOrgId);
      setPolicyHolders(data.holders);
    } catch (error) {
      console.error(error)
    }
  };
  const handlePolicyHolderChange = async (event) => {
    try {
      const selectedPolicyHolder = event.target.value;
      console.log("selectedPolicyHolder", selectedPolicyHolder);
      setSelectedPolicyHolder(selectedPolicyHolder);
      //obtener las polizas dado el id del policy holder
      const data = await getPoliciesByHolder(selectedPolicyHolder);
      console.log("Data de getPoliciesByHolder", data)
      setPolicies(data);
    } catch (error) {
      console.error('Error handling policyHolder change:', error);
    }
  };
  const handlePolicyChange = async (event) => {
    setSelectedPolicy(event.target.value);
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const subgroupToEdit = subgroups.find((subgroup) => subgroup.id_subgroup === id);
    setFormData({ ...subgroupToEdit });
    setShowModal(true);
  };

  const openPdf = (pdfUrl) => {
    const newWindow = window.open(pdfUrl);
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.fetch(pdfUrl, {
        });
      };
    }
  };
  const handleCancelEdit = () => {
    setEditingId(null);
    setFormData({});
    setEdit(false);
  };
  const handleEditSecundary = () => {
    console.log("DAtos subgroup", formData)
    setEdit(true);
  };
  const handleSaveEdit = async () => {
    try {
      console.log("edditingID", editingId)
      await updateSubgroup(editingId, updatedData);
      setEditingId(null);
      setFormData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then();
      setShowModal(false);
      setEdit(false);

    } catch (error) {
      console.error('Error updating Subgroup:', error);
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar este Subgrupo?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteSubgroup(id);
          setSubgroups(prev => prev.filter(subgroups => subgroups.id_subgroup !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El subgrupo se ha eliminado correctamente.',
          }).then();
        } catch (error) {
          console.error('Error deleting policy:', error.response.data.message);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            html: `Ha ocurrido un error al eliminar El subgrupo.<br/><strong>${error.response.data.message}</strong>`,
          });

        }
      }
    });
  };

  const handleOpenUploadExcel = () => {
    setDataFetched(false);
    setExcelUploadOpen(true)
  }

  const handleDataSearch = async () => {
    if (!selectedPolicy == '') {
      const data = await getSubgroupByPolicyId(selectedPolicy);
      console.log("data,", data)
      setSubgroups(data);
      setDataFetched(true);
    }
    setExcelUploadOpen(false)
  }

  const handleClose = async () => {
    setShowModal(false)
  }
  const handleFieldChange = (fieldName, value) => {
    console.log(value)
    setUpdatedData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const openUploadModal = (subgroupId) => {
    setSubgroupId(subgroupId); // Guardar el ID del certificado
    setShowUploadModal(true);
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    setSelectedFiles(null); // Limpiar el archivo seleccionado al cerrar el modal
    setSubgroupId(null); // Limpiar el ID del certificado
  };
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    console.log("a", event.target.files)
  };

  const handleUpload = async () => {
    try {
      if (!selectedFiles) {
        console.error("No hay archivos seleccionados.");
        return;
      }

      // Crear un objeto FormData para enviar los archivos
      const formData = new FormData();
      Array.from(selectedFiles).forEach((file) => {
        formData.append('pdfs[]', file); // 'pdfs[]' es el nombre esperado por el backend
      });

      console.log("FormData creado:", formData);

      const response = await uploadPDF(subgroupId, "subgroup", formData);
      console.log("Respuesta del servidor:", response);

      // Mostrar alerta de éxito
      MySwal.fire({
        title: 'Éxito',
        text: 'Los archivos PDF se subieron correctamente.',
        icon: 'success',
        confirmButtonText: 'Ok',
      });

      closeUploadModal();
    } catch (error) {
      console.error('Error uploading PDFs:', error);

      // Mostrar alerta de error
      MySwal.fire({
        title: 'Error',
        text: 'Hubo un problema al subir los archivos PDF. Inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };



  return (
    <div>
      <div className="w-100 px-3 mx-auto">
        {canDeleteOrCreate && <SubgroupForm />}
        <div className="row">
          <div className="col-md-6">
            <SearchOrganization onSelect={handleOrganizationChange} />
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                name="PolicyHolder_id"
                value={selectedPolicyHolder}
                onChange={handlePolicyHolderChange}
                className="form-control"
                id="PolicyHolder_id"
              >
                <option value="">Selecciona un Contratante</option>
                {policyHolders.map((holder) => (
                  <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                    {holder.id_policy_holder} - {holder.business_name}
                  </option>
                ))}
              </select>
              <label htmlFor="PolicyHolder_id">Contratante</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                name='policy_id'
                value={selectedPolicy}
                onChange={handlePolicyChange}
                className="form-control"
                id="policy_id"

              >
                <option value="">Selecciona una Póliza</option>
                {policies.map(policy => (
                  <option key={policy.id_policy} value={policy.id_policy}>
                    {policy.policy_number}
                  </option>
                ))}
              </select>
              <label htmlFor="policy_id">Póliza</label>
            </div>

          </div>
          {selectedPolicy && (
            <div className="d-flex justify-content mb-3">
              <button className="btn btn-info w-100" onClick={() => handleDataSearch()}>Buscar</button>
              <button className="btn btn-success w-100" onClick={() => handleOpenUploadExcel()}>Cargar Excel</button>
            </div>
          )}
          {excelUploadOpen && (
            <div>
              <SubgroupExcelUpload policy_id={selectedPolicy} />
              <button
                className="btn btn-warning w-100"
                onClick={() => window.location.href = 'https://seguwallet.grmseguros.mx/storage/files/6704484f05897_Plantilla-Carga-Certificados-07102024.xlsx'}
              >
                Descargar Plantilla
              </button>

            </div>
          )}
        </div>
        {dataFetched && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Número de Subgrupo</th>
                <th scope="col">Nombre de Subgrupo</th>
                <th scope="col">Nivel Hospitalario</th>
              </tr>
            </thead>
            <tbody>
              {subgroups.map((subgroup) => (
                <tr key={subgroup.id_subgroup}>
                  <td>{subgroup.subgroup_number}</td>
                  <td>{subgroup.subgroup_name}</td>
                  <td>{subgroup.hospital_level}</td>
                  {/* <td>
                    {subgroup.pdfs.map((pdf, index) => (
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <a href="#" onClick={() => openPdf(pdf.public_link)}>
                          <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                        </a>
                        <button
                          className="btn btn-danger btn-sm"
                          style={{ position: 'absolute', top: '0', right: '0', padding: '0.2rem' }}
                          onClick={() => handleDeletePDF(subgroup.id_subgroup, pdf.id)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </td> */}
                  <td>
                    <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(subgroup.id_subgroup)}>Ver Completo</button>
                    {subgroup.pdfs == 0 && (
                      <button className="btn btn-secondary btn-sm me-1" onClick={() => openUploadModal(subgroup.id_subgroup)}>Subir PDF</button>
                    )}
                    {canDeleteOrCreate && (
                      <button className="btn btn-danger btn-sm" onClick={() => handleDelete(subgroup.id_subgroup)}>Borrar</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Subgrupo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Numero del subgrupo</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={formData.subgroup_number}
                  onChange={(e) => handleFieldChange('subgroup_number', e.target.value)}
                  disabled={!edit}
                />
              </Form.Group>

              <Form.Group controlId="formRFC">
                <Form.Label>Nombre del subgrupo</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={formData.subgroup_name}
                  onChange={(e) => handleFieldChange('subgroup_name', e.target.value)}
                  disabled={!edit}
                />
              </Form.Group>
              <Form.Group controlId="formRFC">
                <Form.Label>Nivel Hospitalario</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={formData.hospital_level}
                  onChange={(e) => handleFieldChange('hospital_level', e.target.value)}
                  disabled={!edit}
                />
              </Form.Group>
              {/* Mostrar sección para subir más PDFs */}
              {/* <Form.Group controlId="formPDFs">
                <Form.Label>Añadir PDF</Form.Label>
                <div
                  {...getRootProps()}
                  style={isDragActive ? activeDropzoneStyle : dropzoneStyle}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>Suelta los archivos aquí...</p> :
                      <p>Arrastra y suelta archivos PDF aquí, o haz clic para seleccionar archivos</p>
                  }
                </div>
              </Form.Group> */}
              <thead>
                <tr>
                  <th>PDF Subgrupo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {formData.pdfs && formData.pdfs.length > 0 ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {formData.pdfs.map((pdf, index) => (
                          <div key={index} style={{ textAlign: 'center', marginRight: '20px' }}>
                            <a href="#" onClick={() => openPdf(pdf.public_link)}>
                              <img src={pdfLogo} alt="PDF" style={{ width: '40px' }} />
                            </a>
                            <br />
                            <p className='small'> {pdf.file_name || `PDF ${index + 1}`}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span>No PDFs available</span>
                    )}
                  </td>
                </tr>
              </tbody>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            {canDeleteOrCreate && (
              <div className="ms-auto">

                {!edit ? (
                  <Button variant="primary" onClick={handleEditSecundary} className="me-2">
                    Editar
                  </Button>
                ) : (
                  <div>
                    <Button variant="success" onClick={handleSaveEdit} className="me-2">
                      Guardar
                    </Button>
                    <Button variant="danger" onClick={handleCancelEdit}>
                      Cancelar
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>Subir PDFs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Seleccionar archivos PDF:</Form.Label>
              <Form.Control type="file" multiple accept="application/pdf" onChange={handleFileChange} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeUploadModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleUpload}>
              Subir PDF
            </Button>


          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}


//Estilos
const dropzoneStyle = {
  border: '2px dashed #6c757d', /* Default border color (secondary) */
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: '10px'
};

const activeDropzoneStyle = {
  ...dropzoneStyle,
  borderColor: '#007bff' /* Border color when active (primary) */
};

export default SubgroupList;
