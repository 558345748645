import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todas los ejecutivos
export const getExecutive = async () => {
  console.log("Entrando a GetExecutiveService");
  try {
    const response = await axios.get(`${apiUrl}/executive`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getExecutive:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching executives:', error);
    throw error;
  }
};

// Método para agregar un nuevo ejecutivo

export const postExecutive = async (executiveData) => {
  try {
    console.log("ExecutiveService Post, Datos: ", executiveData);

    const response = await axios.post(`${apiUrl}/executive`, executiveData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`La informacion a mandar: ${executiveData}`)
    return response.data;
  } catch (error) {
    console.error('Error adding executives:', error);
    throw error;
  }
};
// Método para actualizar ejecutivo existente
export const updateExecutive = async (executiveID, executiveData) => {
  try {
    const response = await axios.patch(`${apiUrl}/executive/${executiveID}`, executiveData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error updating executive:', error);
    throw error;
  }
};
export const deleteExecutive = async (executiveID) => {
  try {
    const response = await axios.delete(`${apiUrl}/executive/${executiveID}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting executive:', error);
    throw error;
  }
};