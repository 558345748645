
import { getOrganizations } from './api/OrganizationService';
import { getRole } from './api/userService';
import { setOrganizations } from './features/organization/organizationSlice';
import { setRole } from './features/user/userSlice';
import store from './app/store'; 
import { getInsurances } from './api/insurerService';
import { setInsurer } from './features/insurer/insurerSlice';
import { setExecutive } from './features/executive/executiveSlice';
import { getExecutive } from './api/executiveService';
import { setPools } from './features/pools/poolsSlice';
import { getPools } from './features/pools/poolService';

export const fetchOrganizations = async () => {
  try {
    const orgData = await getOrganizations();
    if (orgData.organizations && orgData.organizations.length > 0) {
      store.dispatch(setOrganizations(orgData.organizations));
    }
  } catch (error) {
    console.error('Error fetching organizations:', error);
  }
};

export const fetchRole = async () => {
  try {
    const role = await getRole();
    if (role) {
      store.dispatch(setRole(role));
    }
  } catch (error) {
    console.error('Error fetching role:', error);
  }
};
export const fetchPools = async () => {
  try {
    const data = await getPools();
    console.log("Data desde global 38", data)
    if (data) {
      store.dispatch(setPools(data));
    }
  } catch (error) {
    console.error('Error fetching role:', error);
  }
};
const fetchInsurers = async () => {
  try {
    const data = await getInsurances();
    if (data) {
      store.dispatch(setInsurer(data.insurers));
    }
  } catch (error) {
    console.error('Error fetching insurers:', error);
  }
};
const fetchExecutives = async () => {
  try {
    const data = await getExecutive(); // Obtiene los datos de las aseguradoras
    if (data) {
      store.dispatch(setExecutive(data.executives));
    }
  } catch (error) {
    console.error('Error fetching executives:', error);
  }
};
export const infoGlobal = {
  fetchOrganizations,
  fetchRole,
  fetchInsurers,
  fetchExecutives,
  fetchPools
};
export const initializeApp = async () => {
  await fetchRole();
  // await fetchOrganizations();
  // await fetchInsurers();
  // await fetchExecutives();
}
//Logo PDF

export const pdfLogo = 'https://seguwallet.grmseguros.mx/images/logosAdminWeb/pdfLogo.png';
//Logo GRM SeguWallet
export const grmSeguWalletLogo = 'https://seguwallet.grmseguros.mx/images/logosAdminWeb/logo_GRMSeguWallet.png';
export const seguWalletLogo = 'https://seguwallet.grmseguros.mx/images/logosAdminWeb/logoSeguWallet.png';

/*************************/
export const apiUrl = 'https://seguwallet.grmseguros.mx/api';
//export const apiUrl = 'http://127.0.0.1:8000/api';
